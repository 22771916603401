import { post } from "../../services";
import {
    ACTIVATE_HOTEL_PACKAGE,
    ADD_NEW_HOTEL_PACKAGE,
    ADD_NEW_PACKAGE,
    LIST_ALL_HOTEL_PACKAGES,
    LIST_ALL_PACKAGES,
    UPDATE_HOTEL_PACKAGE,
} from "../../graphql";
import {
    RESET_ALL_HOTEL_PACKAGES,
    RESET_ALL_PACKAGES,
    SAVE_ALL_HOTEL_PACKAGES,
    SAVE_ALL_PACKAGES,
    TOGGLE_LOADING,
} from "../types";

export const addNewPackage = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: ADD_NEW_PACKAGE,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            dispatch(resetAllPackages());
            dispatch(listAllPackages());
            return true;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const listAllPackages = (requestData) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });
        const {
            packages: { pageSize, pageNumber, list },
        } = getState().package;

        let apiResponse = await post("", {
            query: LIST_ALL_PACKAGES,
            variables: {
                pageSize: pageSize,
                pageNumber: pageNumber,
                ...requestData,
            },
        });

        if (apiResponse.data.customStatus) {
            let {
                list_all_packages: { data, hasMore },
            } = apiResponse.data.data;
            let updatedList = [...list, ...data];
            dispatch({
                type: SAVE_ALL_PACKAGES,
                payload: {
                    list: updatedList,
                    showViewMore: hasMore,
                    pageNumber: pageNumber + 1,
                },
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetAllPackages = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_ALL_PACKAGES });
    };
};

export const addNewHotelPackage = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: ADD_NEW_HOTEL_PACKAGE,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            dispatch(resetAllHotelPackages());
            dispatch(listAllHotelPackages({ hotelId: requestData?.hotelId}));
            return true;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const listAllHotelPackages = (requestData) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });
        const {
            hotelPackages: { pageSize, pageNumber, list },
        } = getState().package;

        let apiResponse = await post("", {
            query: LIST_ALL_HOTEL_PACKAGES,
            variables: {
                pageSize: pageSize,
                pageNumber: pageNumber,
                ...requestData,
            },
        });

        if (apiResponse.data.customStatus) {
            let {
                list_all_hotel_packages: { data, hasMore },
            } = apiResponse.data.data;
            let updatedList = [...list, ...data];
            dispatch({
                type: SAVE_ALL_HOTEL_PACKAGES,
                payload: {
                    list: updatedList,
                    showViewMore: hasMore,
                    pageNumber: pageNumber + 1,
                },
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetAllHotelPackages = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_ALL_HOTEL_PACKAGES });
    };
};

export const activateHotelPackage = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: ACTIVATE_HOTEL_PACKAGE,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            dispatch(resetAllHotelPackages());
            dispatch(listAllHotelPackages({ hotelId: requestData?.hotelId}));
            dispatch({ type: TOGGLE_LOADING, payload: false });
            return true;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const updateHotelPackage = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: UPDATE_HOTEL_PACKAGE,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            dispatch(resetAllHotelPackages());
            dispatch(listAllHotelPackages({ hotelId: requestData?.hotelId}));
            dispatch({ type: TOGGLE_LOADING, payload: false });
            return true;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};