import React, { useState } from "react";
import { Box, Button, CircularProgress, Stack, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { Close, Warning } from "@mui/icons-material";
import { toast } from "react-toastify";
import Header from "../../components/Header";
import { authorizedPost } from "../../store/actions";
import { CANCEL_INVOICE } from "../../graphql";

function CancelInvoiceModal({ invoiceNo, setCancelInvModal, fetchData }) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [loading, setLoading] = useState(false);

    const modalBoxStyle = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        maxHeight: "90vh",
        width: { sm: 400, xs: "90%" },
        bgcolor: colors.primary[400],
        outline: "none",
        boxShadow: 24,
        p: 2,
    };

    const handleCancelBtn = async() => {
        setLoading(true);
        let res = await authorizedPost(CANCEL_INVOICE, {invNo: invoiceNo})
        setLoading(false);
        if(res.status){
            fetchData();
            toast.success("Invoice cancelled");
            setCancelInvModal(false);
        }
    }

    return (
        <>
            <Box sx={modalBoxStyle}>
                <Stack direction="row" justifyContent="space-between">
                    <Header title="Are you sure to cancel this invoice ?" titleVariant="h4" />
                    <Close onClick={() => setCancelInvModal(false)} sx={{ cursor: "pointer" }} />
                </Stack>
                <Stack mb={2} alignItems="center">
                    <Warning color="warning"/>
                </Stack>
                <Stack p={2} borderRadius={2} textAlign="center" bgcolor={colors.primary[500]}>
                    <Typography>Invoice no</Typography>
                    <Typography variant="h5" fontWeight="bold">
                        {invoiceNo}
                    </Typography>
                </Stack>
                <Stack mt={2} direction="row" spacing={2}>
                    <Button fullWidth variant="contained" onClick={() => setCancelInvModal(false)}>
                        <b>Close</b>
                    </Button>
                    <Button fullWidth variant="outlined" onClick={handleCancelBtn}>
                    {loading ? <CircularProgress color="inherit" size={24} /> : <b>Cancel invoice</b>}
                    </Button>
                </Stack>
            </Box>
        </>
    );
}

export default CancelInvoiceModal;
