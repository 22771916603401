import React, { useState } from 'react';
import { Autocomplete, Box, Button, CircularProgress, MenuItem, Stack } from '@mui/material';
import { Formik } from 'formik';
import Header from '../../components/Header';
import { authorizedPost, listAllInvoices, resetAllInvoices } from '../../store/actions';
import { CREATE_INVOICES } from '../../graphql';
import { CssTextField } from '../../components/TextField';
import moment from 'moment';
import { toast } from 'react-toastify';

function AddNewInvoice({ dispatch, smScreen, setOpenModal, hotelsList, packages }) {
    const [isLoading, setIsLoading] = useState(false);
    const optionsList = hotelsList.map((el, idx) => ({
        label: el.name,
        id: el._id,
    }))

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: smScreen ? 400 : "90%",
        bgcolor: "#1F2A40",
        outline: "none",
        boxShadow: 24,
        p: 2,
    };

    const initialValues = {
        hotelId: "",
        packageId: "",
        customAmount: 0,
        invDate: moment().format("YYYY-MM-DD"),
    };
    const handleFormSubmit = async (values) => {
        if(!values.hotelId) return toast.error("Please select a hotel")
        setIsLoading(true);
        let data = {
            ...values,
            customAmount: values.customAmount || 0,
            invDate: moment(values.invDate, "YYYY-MM-DD").startOf("day").unix(),
            months: [{ month: moment(values.invDate, "YYYY-MM-DD").format("MMM-YYYY") }],
            invType: "normal"
        }
        console.log(data);
        await authorizedPost(CREATE_INVOICES, data)
        dispatch(resetAllInvoices());
        dispatch(listAllInvoices());
        setIsLoading(false);
        setOpenModal(false);
    };

    return (
        <Box>
            <Box sx={modalStyle}>
                <Header title="CREATE NEW INVOICE" titleVariant="h4" />

                <Formik
                    onSubmit={handleFormSubmit}
                    initialValues={initialValues}
                >
                    {({ values, handleChange, handleSubmit, setFieldValue }) => (
                        <form onSubmit={handleSubmit}>

                            <Stack spacing={2}>
                                {/* <CssTextField
                                    fullWidth
                                    required
                                    select
                                    size='small'
                                    type="text"
                                    label="Hotel"
                                    name="hotelId"
                                    value={values.hotelId || ""}
                                    onChange={handleChange}
                                >
                                    <MenuItem value="" sx={{display: "none"}}></MenuItem>
                                    {hotelsList?.map((e) => (
                                        <MenuItem value={e._id} key={e._id}>
                                            {e.name}
                                        </MenuItem>
                                    ))}
                                </CssTextField> */}
                                <Autocomplete
                                    // disablePortal
                                    id="combo-box-demo"
                                    fullWidth
                                    size='small'
                                    options={optionsList}
                                    onChange={(e, value) => setFieldValue("hotelId", value?.id ?? "")}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    renderInput={(params) => <CssTextField required {...params} label="Hotel" />}
                                />
                                <CssTextField
                                    fullWidth
                                    select
                                    size='small'
                                    type="text"
                                    label="Package"
                                    name="packageId"
                                    value={values.packageId || ""}
                                    onChange={handleChange}
                                >
                                    <MenuItem value="" sx={{display: "none"}}></MenuItem>
                                    {packages?.map((e) => (
                                        <MenuItem value={e._id} key={e._id}>
                                            {e.name}
                                        </MenuItem>
                                    ))}
                                </CssTextField>
                                <CssTextField
                                    fullWidth
                                    size='small'
                                    type="number"
                                    label="Custom amount"
                                    name="customAmount"
                                    value={values?.customAmount || ""}
                                    onChange={handleChange}
                                    InputProps={{ inputProps: { min: 0 } }}
                                />
                                <CssTextField
                                    fullWidth
                                    size='small'
                                    type="date"
                                    label="Invoice Date"
                                    name="invDate"
                                    value={values?.invDate}
                                    onChange={handleChange}
                                />
                                <Button
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    color="secondary"
                                    variant="contained"
                                    sx={{ fontSize: "15px" }}
                                >
                                    {isLoading ? <CircularProgress color="inherit" size={24} /> : <b>Submit</b>}
                                </Button>
                            </Stack>
                        </form>
                    )}
                </Formik>
            </Box>
        </Box>
    )
}

export default AddNewInvoice