import { post } from "../../services";
import { toggleLoading } from "./loadingActions";

export const authorizedPost = async (query, variables = {}) => {
    try {
        toggleLoading(true);
        let apiResponse = await post("", {
            query: query,
            variables: variables,
        });

        if (apiResponse.data.customStatus) {
            toggleLoading(false);
            return {
                status: true,
                data: apiResponse.data.data,
            };
        }
        toggleLoading(false);
        return { status: false, data: {} };
    } catch (err) {
        toggleLoading(false);
        return { status: false, data: {} };
    }
};
