import { combineReducers } from "redux";
import { LOGOUT } from "../types";
import loadingReducer from "./loadingReducer";
import authReducer from "./authReducer";
import adminReducer from "./adminReducer";
import packageReducer from "./packageReducer ";

const appReducer = combineReducers({
    auth: authReducer,
    loading: loadingReducer,
    admin: adminReducer,
    package: packageReducer,
});

const rootReducer = (state, action) => {
    if (action.type === LOGOUT) {
        state = undefined;
    }
    return appReducer(state, action);
};

export default rootReducer;
