/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { Box, Button, CircularProgress, Modal, Stack, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import { addNewHotel, authorizedPost, listAllHotels, resetAllHotelsList } from "../../store/actions";
import { GENERATE_HOTEL_TOKEN } from "../../graphql";
import { DataGrid } from "@mui/x-data-grid";
import { Add, ArrowForward } from "@mui/icons-material";
import { tokens } from "../../theme";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import Header from "../../components/Header";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";

const Hotels = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [hotelId, setHotelId] = useState("");
  const [search, setSearch] = useState("");
  const [filtered, setFiltered] = useState([]);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { isLoading, hotelsList, showViewMore } = useSelector(
    ({
      loading,
      admin: {
        hotels: { list, showViewMore },
      },
    }) => ({
      isLoading: loading,
      hotelsList: list,
      showViewMore,
    })
  );
  
  useEffect(() => {
    document.title = 'Hotels';
    dispatch(resetAllHotelsList());
    fetchMoreData();
  },[dispatch]);

  const fetchMoreData = () => {
    dispatch(listAllHotels());
  }

  useEffect(() => {
    var filt = hotelsList?.filter((e) => e?.name?.indexOf(search?.toLowerCase()) > -1);
    setFiltered(filt);
}, [search, hotelsList]);

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: smScreen ? 400 : "90%",
    bgcolor: "#1F2A40",
    outline: "none",
    boxShadow: 24,
    p: 2,
  };

  const handleFormSubmit = async(values) => {
    console.log(values);
    await dispatch(addNewHotel(values));
    handleClose();
  };
  const initialValues = {
    name: "",
    email: "",
    address: "",
    mobile: "",
    type: "",
  };
  const checkoutSchema = yup.object().shape({
    name: yup.string().required("Required"),
    email: yup.string().email().required("Required"),
    address: yup.string().required("Required"),
  });
  
  const columns = [
    { field: "name", headerName: "Name", cellClassName: "name-column--cell", width: 200  },
    { field: "email", headerName: "Email", width: 200  },
    { field: "address", headerName: "Address", width: 200 },
    { field: "status", headerName: "Status", width: 100 },
    {
      field: "is_active",
      headerName: "Availability",
      width: 100,
      renderCell: ({ row: { is_active } }) => {
        return (
          <Box
            // width="100%"
            m="0 auto"
            p="0 5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
              is_active === true
                ? colors.greenAccent[700]
                : colors.redAccent[700]
            }
            borderRadius="10px"
          >
            <Typography color={colors.grey[100]}>
              {is_active ? "active" : "inactive"}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "_id",
      headerName: "Login to hotel",
      width: 110,
      renderCell: ({ row: { _id } }) => {
        return (
          <Button
            size="small"
            variant="outlined"
            onClick={() => {
              setHotelId(_id)
              setLoginModal(true)
            }}
          >
            <b>Login</b>
          </Button>
        );
      },
    },
  ];

  if(localStorage.getItem("accountType") === "admin") {
    columns.push(
      {
        field: "",
        headerName: "View details",
        width: 110,
        renderCell: ({ row: { _id } }) => {
          return (
            <Button 
              size="small" 
              variant="contained" 
              onClick={() => navigate(`/hotel-details/${_id}`)}
              disabled={localStorage.getItem("accountType") !== "admin"}
            >
              <ArrowForward fontSize="small" />
            </Button>
          )
        },
      },
    )
  }

  const handleHotelLoginButton = async() => {
    let result = await authorizedPost(GENERATE_HOTEL_TOKEN, { hotelId: hotelId });
    if(result?.data){
      let token = result.data.generate_hotel_token.token;
      window.open(`https://hotels.travelnet.in/admin-login/${token}`);
      setLoginModal(false);
    }
  }
  
  return (
    <Box m="20px" mb={15}>
      <Box         
        display={smScreen ? "flex" : "block"}
        flexDirection={smScreen ? "row" : "column"}
        justifyContent={smScreen ? "space-between" : "start"}
        alignItems={smScreen ? "center" : "start"}
        m="20px 0"
      >
        <Header title="HOTELS" subtitle="welcome to Hotels" />

        {localStorage.getItem("accountType") === "admin" ? (
        <Box>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
            onClick={handleOpen}
            disabled={localStorage.getItem("accountType") !== "admin"}
          >
            <Add sx={{ mr: "10px" }} />
            Add new hotel
          </Button>
        </Box>
        ) : null}
      </Box>
      <Box>
        <TextField
          size="small"
          variant="outlined"
          label="Search by name"
          value={search}
          onChange={(e) => setSearch(e?.target?.value)}
          sx={{ width: 150 }}
        />
      </Box>
      <Box
        m="8px 0 0 0"
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
            fontSize: 16,
          },
          "& .MuiDataGrid-cell": {
            // borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid rows={search !== "" ? filtered : hotelsList} columns={columns} getRowId={(row) => row?._id}/>
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
      >
        <Box sx={modalStyle}>
          <Header title="ADD NEW HOTEL" titleVariant="h4" />

          <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            validationSchema={checkoutSchema}
          >
            {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
              <form onSubmit={handleSubmit}>

                <Stack spacing={2}>
                  <TextField
                    fullWidth
                    size="small"
                    variant="filled"
                    type="text"
                    label="Hotel Name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.name}
                    name="name"
                    error={!!touched.name && !!errors.name}
                    helperText={touched.name && errors.name}
                  />
                  <TextField
                    fullWidth
                    size="small"
                    variant="filled"
                    type="email"
                    label="Email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    name="email"
                    error={!!touched.email && !!errors.email}
                    helperText={touched.email && errors.email}
                  />
                  <TextField
                    fullWidth
                    size="small"
                    variant="filled"
                    type="text"
                    label="Address"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.address}
                    name="address"
                    error={!!touched.address && !!errors.address}
                    helperText={touched.address && errors.address}
                  />
                  <TextField
                    fullWidth
                    size="small"
                    variant="filled"
                    type="number"
                    label="Mobile"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.mobile}
                    name="mobile"
                    error={!!touched.mobile && !!errors.mobile}
                    helperText={touched.mobile && errors.mobile}
                  />
                  <TextField
                    fullWidth
                    size="small"
                    variant="filled"
                    type="text"
                    label="Type"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.type}
                    name="type"
                    error={!!touched.type && !!errors.type}
                    helperText={touched.type && errors.type}
                  />
                  <Button
                    fullWidth
                    size="large"
                    type="submit"
                    color="secondary"
                    variant="contained"
                    sx={{ fontSize: "15px" }}
                  >
                    {isLoading ? <CircularProgress color="inherit" size={24} /> : <b>Submit</b>}
                  </Button>
                </Stack>
              </form>
            )}
          </Formik>
        </Box>
      </Modal>

      <Modal
        open={loginModal}
        onClose={() => setLoginModal(false)}
      >
        <Box sx={modalStyle}>
          <Stack spacing={5}>
            <Typography variant="h4" fontWeight={600}>Are you sure to login?</Typography>
            <Stack direction={"row"} spacing={1}>
            <Button fullWidth variant="outlined" onClick={() => setLoginModal(false)}>
                <b>Cancel</b>
              </Button>
              <Button fullWidth variant="contained" onClick={handleHotelLoginButton}>
                {isLoading ? <CircularProgress color="inherit" size={24} /> : <b>Login</b>}
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </Box>
  );
};

export default Hotels;
