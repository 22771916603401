import {
    SAVE_ALL_ROOMS_LIST,
    RESET_ALL_ROOMS_LIST,
    SAVE_ALL_HOTELS_LIST,
    RESET_ALL_HOTELS_LIST,
    SAVE_ALL_HOTEL_LOGS,
    RESET_ALL_HOTEL_LOGS,
    SAVE_ALL_BOOKINGS,
    RESET_ALL_BOOKINGS,
    SAVE_ALL_INVOICES,
    RESET_ALL_INVOICES,
} from "../types";

const INITIAL_STATE = {
    rooms: {
        list: [],
        showViewMore: false,
        pageNumber: 1,
        pageSize: 100,
    },
    hotels: {
        list: [],
        showViewMore: false,
        pageNumber: 1,
        pageSize: 50,
    },
    hotelLogs: {
        list: [],
        showViewMore: false,
        pageNumber: 1,
        pageSize: 50,
        count: 1,
    },
    bookings: {
        list: [],
        showViewMore: false,
        pageNumber: 1,
        pageSize: 50,
        count: 1,
    },
    invoices: {
        list: [],
        showViewMore: false,
        pageNumber: 1,
        pageSize: 100,
        count: 1,
    },
};

const adminReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        
        case SAVE_ALL_ROOMS_LIST:
            return {
                ...state,
                rooms: {
                    pageSize: 10,
                    ...payload,
                },
            };

        case RESET_ALL_ROOMS_LIST:
            return {
                ...state,
                rooms: INITIAL_STATE.rooms,
            };

        case SAVE_ALL_HOTELS_LIST:
            return {
                ...state,
                hotels: {
                    pageSize: 10,
                    ...payload,
                },
            };

        case RESET_ALL_HOTELS_LIST:
            return {
                ...state,
                hotels: INITIAL_STATE.hotels,
            };

        case SAVE_ALL_HOTEL_LOGS:
            return {
                ...state,
                hotelLogs: {
                    pageSize: 10,
                    ...payload,
                },
            };

        case RESET_ALL_HOTEL_LOGS:
            return {
                ...state,
                hotelLogs: INITIAL_STATE.hotelLogs,
            };

        case SAVE_ALL_BOOKINGS:
            return {
                ...state,
                bookings: {
                    pageSize: 10,
                    ...payload,
                },
            };

        case RESET_ALL_BOOKINGS:
            return {
                ...state,
                bookings: INITIAL_STATE.bookings,
            };

        case SAVE_ALL_INVOICES:
            return {
                ...state,
                invoices: {
                    pageSize: 10,
                    ...payload,
                },
            };

        case RESET_ALL_INVOICES:
            return {
                ...state,
                invoices: INITIAL_STATE.invoices,
            };

        default:
            return state;
    }
};

export default adminReducer;
