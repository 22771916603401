// /*eslint-disable*/
import React, { useEffect, useRef, useState } from "react";
import { Autocomplete, Box, Button, Checkbox, IconButton, InputAdornment, MenuItem, Modal, Stack, TextField, useMediaQuery, useTheme } from "@mui/material";
import { listAllHotels, listAllInvoices, listAllPackages, resetAllHotelsList, resetAllInvoices, resetAllPackages } from "../../store/actions";
import { useSelector, useDispatch } from "react-redux";
import { tableStyle } from "../mui-styles/table";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { PaymentStatusChip, StatusChip } from "../../components/Chips";
import { Add, ArrowForward, CalendarMonth, Download } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { PaginationFooter } from "../../components";
import Header from "../../components/Header";
import AddNewInvoice from "./addNewInvoice";
import CsvDownloader from "react-csv-downloader";
import moment from "moment";

// const DarkTooltip = styled(({ className, ...props }) => (
//     <Tooltip {...props} classes={{ popper: className }} />
// ))(({ theme }) => ({
//     [`& .${tooltipClasses.tooltip}`]: {
//         backgroundColor: theme.palette.common.white,
//         color: 'rgba(0, 0, 0, 0.87)',
//         boxShadow: theme.shadows[1],
//         fontSize: 16,
//     },
// }));

const Invoices = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
    const colors = tokens(theme.palette.mode);

    const startInputRef = useRef();
    const endInputRef = useRef();
    const [hotelId, setHotelId] = useState("");
    // const [search, setSearch] = useState("");
    // const [filtered, setFiltered] = useState([]);
    const [startDate, setStartDate] = useState(0);
    const [endDate, setEndDate] = useState(0);
    const [withoutCancelled, setWithoutCancelled] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState("");
    const allStatuses = [
        { name: "paid", value: "paid" },
        { name: "unpaid", value: "unpaid" },
        { name: "partial", value: "partial_pmt" },
    ]

    const { hotelsList, invoices, packages } = useSelector(
        ({
            admin: {
                hotels: { list },
                invoices,
            },
            package: { packages },
        }) => ({
            hotelsList: list,
            invoices,
            packages,
        })
    );

    const optionsList = hotelsList.map((el, idx) => ({
        label: el.name,
        id: el._id,
    }))

    useEffect(() => {
        dispatch(resetAllHotelsList());
        dispatch(listAllHotels());
        dispatch(resetAllPackages());
        dispatch(listAllPackages());
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        dispatch(resetAllInvoices());
        fetchMoreData();
        // eslint-disable-next-line
    }, [dispatch, hotelId, selectedStatus, startDate, endDate])

    // useEffect(() => {
    //     if (startDate && endDate) {
    //         dispatch(resetAllInvoices());
    //         fetchMoreData();
    //     }
    //     // eslint-disable-next-line
    // }, [startDate, endDate])

    const fetchMoreData = (page) => {
        dispatch(listAllInvoices({ hotelId: hotelId, status: selectedStatus, ...page, startDate, endDate }));
    }

    useEffect(() => {
        var without_cancelled = invoices?.list?.filter((e) => e.is_active === true);
        // var filt = without_cancelled?.filter((e) => e?.hotel_id?.name?.indexOf(search?.toLowerCase()) > -1);
        setWithoutCancelled(without_cancelled);
        // setFiltered(filt);
    }, [invoices]);

    const CustomFooter = () => {
        return (
            <PaginationFooter
                count={invoices?.count}
                pageSize={invoices?.pageSize}
                pageNumber={invoices?.pageNumber}
                fetchMoreData={(page) => fetchMoreData(page)}
            />
        );
    }

    const generateCsvDownloadData = (data) => {
        let filteredData = data.map((row) => (
            {
                "Date": moment.unix(row.inv_date).format("DD-MMM-YYYY"),
                "Invoice Number": row.inv_no,
                "Hotel": row.hotel_id?.name,
                "Net Total": row.custom_amount ? row.custom_amount : row.total_amount,
                "Paid Amount": row.paid_amount,
                "Balance Amount": row.bal_amount,
                "Payment Status": row.pmt_status,
                "Invoice Status": row.is_active ? "active" : "cancelled",
            }
        ));
        return filteredData;
    };

    const columns = [
        // { field: "_id", headerName: "No", width: 200 },
        {
            field: "inv_date",
            headerName: "Date",
            width: 110,
            valueGetter: (params) => `${params.row.inv_date ? moment.unix(params.row.inv_date).format("YYYY-MM-DD") : "-"}`,
        },
        {
            field: "inv_no",
            headerName: "Invoice No.",
            width: 200,
            cellClassName: "id-column--cell",
        },
        {
            field: "hotel_id",
            headerName: "Hotel",
            width: 170,
            renderCell: ({ row: { hotel_id } }) => `${hotel_id?.name || "-"}`,
        },
        // { 
        //     field: "to", 
        //     headerName: "To", 
        //     width: 110,
        //     renderCell: ({ row: { to } }) => `${to || "-"}`, 
        // },
        // { field: "tax", headerName: "Tax", width: 70, renderCell: ({ row: { tax } }) => `${tax || "-"}`, },
        {
            field: "total_amount",
            headerName: "Net Total",
            width: 100,
            renderCell: ({ row: { total_amount, custom_amount } }) => custom_amount ? custom_amount : total_amount,
        },
        { field: "paid_amount", headerName: "Paid Amount", width: 90 },
        { field: "bal_amount", headerName: "Balance Amount", width: 90 },
        // { field: "email_status", headerName: "Email status", width: 120 },
        {
            field: "pmt_status",
            headerName: "Payment Status",
            width: 120,
            renderCell: ({ row: { pmt_status } }) => {
                return (
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <PaymentStatusChip status={pmt_status} />
                    </Stack>
                );
            },
        },
        {
            field: "is_active",
            headerName: "Invoice Status",
            width: 120,
            renderCell: ({ row: { is_active } }) => {
                return (
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <StatusChip status={is_active ? "active" : "cancelled"} />
                    </Stack>
                );
            },
        },
        // { 
        //     field: "remarks", 
        //     headerName: "Remarks", 
        //     width: 200, 
        //     renderCell: ({ row: { remarks } }) => (
        //         <DarkTooltip title={remarks}>
        //             <Typography noWrap>{remarks || "-"}</Typography>
        //         </DarkTooltip>
        //     ), 
        // },
        {
            field: "_id",
            headerName: "View Details",
            width: 110,
            renderCell: ({ row: { inv_no } }) => {
                return (
                    <Button
                        size="small"
                        variant="contained"
                        onClick={() => navigate(`/invoice-details/${inv_no}`)}
                        endIcon={<ArrowForward fontSize="small" />}
                        sx={{ fontWeight: 600 }}
                    >
                        VIEW
                    </Button>
                )
            },
        },
    ];

    return (
        <Box m="20px" mb={15}>
            <Box
                display={smScreen ? "flex" : "block"}
                flexDirection={smScreen ? "row" : "column"}
                justifyContent={smScreen ? "space-between" : "start"}
                alignItems={smScreen ? "center" : "start"}
                m="20px 0"
            >
                <Header title="INVOICES" subtitle="welcome to Invoices" />
                <Box>
                    <Button
                        sx={{
                            backgroundColor: colors.blueAccent[700],
                            color: colors.grey[100],
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px",
                        }}
                        onClick={() => setOpenModal(true)}
                        disabled={localStorage.getItem("accountType") !== "admin"}
                    >
                        <Add sx={{ mr: "10px" }} />
                        Create new invoice
                    </Button>
                </Box>
            </Box>
            <Box display="flex" gap={1} flexDirection={{ xs: "column", md: "row" }}>
                <Stack direction={"row"} spacing={1} flexGrow={1}>
                    <TextField
                        fullWidth
                        size="small"
                        type="date"
                        label="Start Date"
                        placeholder="Start Date"
                        sx={{ width: 200 }}
                        value={startDate ? moment.unix(startDate).format("YYYY-MM-DD") : ""}
                        onChange={(newValue) => {
                            setStartDate(moment(newValue.target.value, "YYYY-MM-DD").startOf("day").unix())
                            if (endDate !== "" && moment(newValue.target.value).add(1, "days").format("YYYY-MM-DD") > moment.unix(endDate).format("YYYY-MM-DD")) {
                                setEndDate(moment(newValue.target.value, "YYYY-MM-DD").add(1, "days").endOf("day").unix());
                            }
                            if(!newValue.target.value) {
                                setStartDate(0)
                                setEndDate(0)
                            }
                        }}
                        inputRef={startInputRef}
                        onClick={() => startInputRef.current.showPicker()}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <CalendarMonth sx={{ cursor: "pointer" }} />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        fullWidth
                        size="small"
                        type="date"
                        label="End Date"
                        placeholder="End Date"
                        sx={{ width: 200 }}
                        inputProps={{ min: moment.unix(startDate).format("YYYY-MM-DD") }}
                        value={endDate ? moment.unix(endDate).format("YYYY-MM-DD") : ""}
                        onChange={(newValue) => {
                            setEndDate(moment(newValue.target.value, "YYYY-MM-DD").endOf("day").unix())
                            if(!newValue.target.value) {
                                setStartDate(0)
                                setEndDate(0)
                            }
                        }}
                        inputRef={endInputRef}
                        onClick={() => endInputRef.current.showPicker()}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <CalendarMonth sx={{ cursor: "pointer" }} />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Stack>
                <Stack direction={"row"} spacing={1}>
                    <Autocomplete
                        // disablePortal
                        id="combo-box-demo"
                        fullWidth
                        size='small'
                        options={optionsList}
                        onChange={(e, value) => setHotelId(value?.id ?? "")}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderInput={(params) => <TextField {...params} label="Filter by hotel" />}
                        sx={{ width: 250 }}
                    />
                    {/* <TextField
                        select
                        size="small"
                        variant="outlined"
                        label="Hotel"
                        value={hotelId || ""}
                        sx={{ width: 200 }}
                        onChange={(e) => setHotelId(e.target.value)}
                    >
                        {hotelsList?.map((e) => (
                            <MenuItem value={e._id} key={e._id}>
                                {e.name}
                            </MenuItem>
                        ))}
                    </TextField> */}
                    <TextField
                        select
                        size="small"
                        variant="outlined"
                        label="Filter by status"
                        value={selectedStatus ? selectedStatus : ""}
                        onChange={(e) => setSelectedStatus(e?.target?.value)}
                        sx={{ width: 180 }}
                    >
                        <MenuItem value={""}>
                            <em>All</em>
                        </MenuItem>
                        {allStatuses?.map((e) => (
                            <MenuItem value={e.value} key={e.value}>
                                <Checkbox size="small" sx={{ p: "0 5px 0 0" }} checked={selectedStatus === e.value} />
                                {e.name}
                            </MenuItem>
                        ))}
                    </TextField>
                    <CsvDownloader
                        filename={`Invoices ${moment().format("DD-MMM-YYYY hh_mm")}`}
                        disabled={!withoutCancelled.length}
                        datas={generateCsvDownloadData(withoutCancelled || [])}
                    >
                        <IconButton
                            title="Export as CSV"
                            sx={{ borderRadius: "5px", bgcolor: colors.greenAccent[700] }}
                        >
                            <Download />
                        </IconButton>
                    </CsvDownloader>
                </Stack>
            </Box>
            <Box
                m="8px 0 0 0"
                height="80vh"
                sx={tableStyle(colors)}
            >
                <DataGrid
                    rows={withoutCancelled || []}
                    columns={columns}
                    getRowId={(row) => row?._id}
                    components={{ Footer: CustomFooter }}
                />
            </Box>

            <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
            >
                <>
                    <AddNewInvoice
                        dispatch={dispatch}
                        smScreen={smScreen}
                        setOpenModal={setOpenModal}
                        hotelsList={hotelsList || []}
                        packages={packages?.list || []}
                    />
                </>
            </Modal>
        </Box>
    );
};

export default Invoices;
