/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { Box, Button, CircularProgress, MenuItem, Modal, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { activateHotelPackage, addNewHotelPackage, listAllHotelPackages, listAllPackages, resetAllHotelPackages, resetAllPackages, updateHotelPackage } from "../../../store/actions";
import { CssTextField } from "../../../components/TextField";
import { useSelector } from "react-redux";
import { tableStyle } from "../../mui-styles/table";
import { DataGrid } from "@mui/x-data-grid";
import { Formik } from "formik";
import { Add } from "@mui/icons-material";
// import { PaymentStatusChip } from "../../../components/Chips";
// import AvailabilityUpdation from "../../../components/AvailabilityUpdation";
// import UpdatePaymentStatus from "./updatePaymentStatus";
import Header from "../../../components/Header";
import moment from "moment";
import * as yup from "yup";

const HotelPackages = ({hotelId, dispatch, colors}) => {
    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.up("sm"));

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const { isLoading, hotelPackagesList, allPackages, showViewMore } = useSelector(
        ({
            loading,
            package: {
                hotelPackages: { list, showViewMore },
                packages,
            },
        }) => ({
            isLoading: loading,
            hotelPackagesList: list,
            allPackages: packages,
            showViewMore,
        })
    );

    useEffect(() => {
        dispatch(resetAllPackages());
        dispatch(listAllPackages());
        dispatch(resetAllHotelPackages());
        fetchMoreData();
    }, [dispatch])

    const fetchMoreData = () => {
        dispatch(listAllHotelPackages({ hotelId: hotelId }));
    }

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: smScreen ? 400 : "90%",
        bgcolor: "#1F2A40",
        outline: "none",
        boxShadow: 24,
        p: 2,
    };

    const initialValues = {
        packageId: "",
        startDate: moment().format("YYYY-MM-DD"),
        endDate: moment().add(1, "days").format("YYYY-MM-DD"),
        plan: 0,
        discountPerc: 0,
    };
    const checkoutSchema = yup.object().shape({
        // plan: yup.string().required("Required"),
    });
    const handleFormSubmit = async (values) => {
        let data = {
            ...values,
            hotelId: hotelId,
            startDate: moment(values.startDate, "YYYY-MM-DD").unix(),
            endDate: moment(values.endDate, "YYYY-MM-DD").unix(),
        }
        console.log(data);
        await dispatch(addNewHotelPackage(data));
        handleClose();
    };

    const columns = [
        { 
            field: "package_id", 
            headerName: "Package", 
            cellClassName: "name-column--cell",
            width: 200,
            renderCell: ({ row: { package_id } }) => `${package_id?.name || "-"}`, 
        },
        { 
            field: "start_date", 
            headerName: "Start Date", 
            width: 120,
            renderCell: ({ row: { start_date } }) => `${start_date ? moment.unix(start_date).format("DD-MMM-YYYY") : "-"}`, 
        },
        // { 
        //     field: "end_date", 
        //     headerName: "End Date", 
        //     width: 120,
        //     renderCell: ({ row: { end_date } }) => `${end_date ? moment.unix(end_date).format("DD-MMM-YYYY") : "-"}`, 
        // },
        // { 
        //     field: "plan", 
        //     headerName: "Plan", 
        //     width: 100,
        //     renderCell: ({ row: { plan } }) => `${plan || "-"}`,
        // },
        { 
            field: "discount_perc", 
            headerName: "Discount", 
            width: 100,
            renderCell: ({ row: { discount_perc } }) => `${`${discount_perc} %` || "-"}`,
        },
        {
            field: "is_active",
            headerName: "Availability",
            width: 100,
            renderCell: ({ row: { is_active, _id } }) => {
                return (
                    `${is_active ? "active" : "inactive"}`
                    // <AvailabilityUpdation
                    //     isActive={is_active}
                    //     action={() => dispatch(activateHotelPackage({ id: _id, hotelId: hotelId }))}
                    // />
                );
            },
        },
        // { 
        //     field: "pmt_status", 
        //     headerName: "Payment Status", 
        //     width: 120,
        //     renderCell: ({ row: { pmt_status, _id } }) => {
        //         return (
        //             <Stack direction="row" alignItems="center" spacing={2}> 
        //                 {/* <Typography>{pmt_status}</Typography> */}
        //                 <PaymentStatusChip status={pmt_status}/>
        //                 <UpdatePaymentStatus
        //                     status={pmt_status}
        //                     action={() => dispatch(updateHotelPackage({ 
        //                         id: _id, 
        //                         hotelId: hotelId, 
        //                         pmtStatus: pmt_status === "unpaid" ? "paid" : "unpaid", 
        //                     }))}
        //                 />
        //             </Stack>
        //         );
        //     },
        // },
    ];

    return (
        <Box>
            <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                m="20px 0"
            >
                <Typography fontWeight="bold" variant={smScreen ? "h3" : "h4"}>
                    HOTEL PACKAGES
                </Typography>

                <Box>
                    <Button
                        sx={{
                            backgroundColor: colors.blueAccent[700],
                            color: colors.grey[100],
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: {sm: "10px 20px", xs: "5px 10px"},
                        }}
                        onClick={handleOpen}
                        disabled={localStorage.getItem("accountType") !== "admin"}
                    >
                        <Add sx={{ mr: "10px" }} />
                        Link a package
                    </Button>
                </Box>
            </Box>
            <Box
                m="8px 0 0 0"
                height="80vh"
                sx={tableStyle(colors)}
            >
                <DataGrid rows={hotelPackagesList} columns={columns} getRowId={(row) => row?._id} />
            </Box>

            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={modalStyle}>
                    <Header title="LINK A PACKAGE" titleVariant="h4" />

                    <Formik
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={checkoutSchema}
                    >
                        {({ values, handleChange, handleSubmit }) => (
                            <form onSubmit={handleSubmit}>

                                <Stack spacing={2}>
                                    <CssTextField
                                        fullWidth
                                        required
                                        select
                                        size="small"
                                        label="Package"
                                        value={values.packageId}
                                        name="packageId"
                                        onChange={handleChange}
                                    >
                                        {allPackages?.list?.map((e, i) => (
                                            <MenuItem value={e._id} key={i}>
                                                {e.name}
                                            </MenuItem>
                                        ))}
                                    </CssTextField>
                                    <CssTextField
                                        fullWidth
                                        required
                                        size="small"
                                        type="date"
                                        label="start date"
                                        placeholder="Start date"
                                        name="startDate"
                                        value={moment(values.startDate).format("YYYY-MM-DD")}
                                        inputProps={{ min: moment().format("YYYY-MM-DD") }}
                                        onChange={handleChange}
                                    />
                                    {/* <CssTextField
                                        fullWidth
                                        required
                                        size="small"
                                        type="date"
                                        label="End date"
                                        placeholder="End date"
                                        name="endDate"
                                        value={moment(values.endDate).format("YYYY-MM-DD")}
                                        inputProps={{ min: moment(values.startDate).format("YYYY-MM-DD") }}
                                        onChange={handleChange}
                                    /> */}
                                    {/* <CssTextField
                                        fullWidth
                                        required
                                        size="small"
                                        type="number"
                                        label="Plan"
                                        placeholder="Plan"
                                        name="plan"
                                        value={values?.plan}
                                        onChange={handleChange}
                                    /> */}
                                    <CssTextField
                                        fullWidth
                                        required
                                        size="small"
                                        type="number"
                                        label="Discount percentage"
                                        placeholder="Discount percentage"
                                        name="discountPerc"
                                        value={values?.discountPerc}
                                        onChange={handleChange}
                                    />
                                    <Button
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        color="secondary"
                                        variant="contained"
                                        sx={{ fontSize: "15px" }}
                                    >
                                        {isLoading ? <CircularProgress color="inherit" size={24} /> : <b>Submit</b>}
                                    </Button>
                                </Stack>
                            </form>
                        )}
                    </Formik>
                </Box>
            </Modal>
        </Box>
    );
};

export default HotelPackages;
