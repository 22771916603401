import moment from "moment";

const InvoiceTemplate = ({ data, hotel, refer }) => {
  return (
    <>
      <table id="bg" style={{ borderSpacing: 0, width: '100%', backgroundColor: '#3f494b', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '30px', paddingRight: '30px' }}>
        <tbody><tr>
          <td>
            <table ref={refer} id="con-1" style={{ borderSpacing: 0, width: 'auto', maxWidth: '600px', marginTop: 0, marginBottom: 0, marginLeft: 'auto', marginRight: 'auto', paddingTop: '30px', paddingBottom: '30px', paddingLeft: '30px', paddingRight: '30px', backgroundColor: '#fff' }}>
              <tbody>
                <tr>
                  <td>
                    <h3 style={{ color: '#000', textAlign: "center", textDecoration: "underline", fontFamily: "Arial, Helvetica, sans-serif" }}>PROFORMA INVOICE</h3>
                  </td>
                </tr>
                <tr>
                <td>
                  <table id="head-con" style={{ borderSpacing: 0, width: '100%', marginBottom: '10px' }}>
                    <tbody><tr>
                      <td id="head" style={{ width: '125px' }}>
                        <img src="https://marvel-hotels.s3.amazonaws.com/68c0ea84-3bdd-4781-9cae-fe26d00858ca.jpeg?w=164&h=164&fit=crop&auto=format" style={{ width: '65%' }} alt=""/>
                      </td>
                      <td id="detail" style={{ borderSpacing: 0, fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'start' }}>
                        <ul style={{ fontSize: '10px', fontWeight: 600, listStyle: 'none', color: '#909090' }}>
                          <li>
                            <h2 style={{ color: '#000' }}>Perfect Hands Solutions Pvt.Ltd</h2>
                          </li>
                          <li>Earthen Cube, Bund Road, Off NH 47 Bypass, Maradu, Kochi - 682 304</li>
                          <li>
                            <table style={{ fontSize: '10px', fontWeight: 600, listStyle: 'none', color: '#909090', marginTop: '10px' }}>
                              <tbody><tr>
                                <td>Contact</td>
                                <td>: +91 9544 7000 13 , +91 9544 7000 14</td>
                              </tr>
                                <tr>
                                  <td>E-mail</td>
                                  <td>: info@perfecthandssolutions.com</td>
                                </tr>
                                <tr>
                                  <td>Website</td>
                                  <td>: www.perfecthandssolutions.com</td>
                                </tr>
                                <tr>
                                  <td>PAN</td>
                                  <td>: AAGCP4163N</td>
                                </tr>
                                <tr>
                                  <td>GST Number</td>
                                  <td>: 32AAGCP4163N1Z7</td>
                                </tr>
                              </tbody></table>
                          </li>
                        </ul>
                      </td>
                    </tr>
                    </tbody></table>
                  <hr />
                  <table id="table-list" style={{ width: '100%', paddingTop: '20px' }}>
                    <tbody><tr>
                      <td style={{ fontFamily: 'Arial, Helvetica, sans-serif', display: 'inline-block', paddingTop: '4px', width: '200px' }}>
                        <table id="table-1" style={{ borderSpacing: 0 }}>
                          <tbody><tr>
                            <th style={{ textAlign: 'left', fontSize: '11px', color: '#002a63' }}>BILL TO</th>
                          </tr>
                            <tr>
                              <td className="row-2-col-1" style={{ color: '#000', fontSize: '11px' }}>
                                <ul style={{ margin: 0, padding: 0, listStyle: 'none', paddingTop: '7px', marginRight: '10px' }}>
                                  <li style={{ marginTop: '5px', marginLeft: 0 }}>{data?.hotel_id?.name || "-"}, {hotel?.address || ""}</li>
                                </ul>
                              </td>
                            </tr>
                            <tr>
                              <td id="contact" style={{ marginTop: '15px', marginBottom: '15px', marginLeft: 0, marginRight: 0, paddingLeft: '2px', paddingTop: '10px' }}>
                                <p style={{ fontSize: '11px', color: '#000', marginTop: 0, marginBottom: '5px', marginLeft: 0, marginRight: 0 }}><img src="https://marvel-hotels.s3.amazonaws.com/a273290a-f6d7-4848-ba99-c796d84b28f1.png" style={{ width: '10px' }} alt=""/>
                                  {hotel?.email || "-"}
                                </p>
                                <p style={{ fontSize: '11px', color: '#000', marginTop: 0, marginBottom: '5px', marginLeft: 0, marginRight: 0 }}><img src="https://marvel-hotels.s3.amazonaws.com/382ef18d-b4dd-4b72-90d4-296e5ba588b7.png" style={{ width: '8px' }} alt=""/>
                                  {hotel?.mobile || "-"}
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table style={{ marginTop: '20px' }}>
                          <tbody><tr>
                            <td>
                              <p style={{ fontSize: '11px', color: '#000', marginTop: 0, marginBottom: '5px', marginLeft: 0, marginRight: '10px' }}>
                                GST Number
                              </p>
                            </td>
                            <td>
                              <p style={{ fontSize: '11px', color: '#000', marginTop: 0, marginBottom: '5px', marginLeft: 0, marginRight: 0 }}>: 
                                {hotel?.gstin || "-"}
                              </p>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </td>
                      <td id="invoice" style={{ textAlign: 'right', fontFamily: 'Arial, Helvetica, sans-serif' }}>
                        <h2 style={{ fontSize: '15px', fontWeight: 'bold', textAlign: 'right', color: '#4d4d4d', margin: 0 }}>INVOICE DETAILS</h2>
                        <table id="list" style={{ borderSpacing: 0, marginTop: '10px', marginBottom: 0, marginLeft: 'auto', marginRight: 0 }}>
                          <tbody><tr>
                            <td>
                              <ul style={{ margin: 0, listStyle: 'none' }}>
                                <li>
                                  <h6 style={{ fontSize: '11px', color: '#002a63', margin: 0 }}>INVOICE NO</h6>
                                </li>
                                <li>
                                  <p style={{ color: '#696969', fontSize: '10px', marginTop: '5px', marginBottom: '22px', marginLeft: 0, marginRight: 0 }}>
                                    {data?.inv_no || "-"}
                                  </p>
                                </li>
                              </ul>
                              <ul style={{ margin: 0, listStyle: 'none' }}>
                                <li>
                                  <h6 style={{ fontSize: '11px', color: '#002a63', margin: 0 }}>INVOICE DATE</h6>
                                </li>
                                <li>
                                  <p style={{ color: '#696969', fontSize: '10px', marginTop: '5px', marginBottom: '22px', marginLeft: 0, marginRight: 0 }}>
                                    {moment.unix(data?.inv_date).format("DD-MM-YYYY") || "-"}
                                  </p>
                                </li>
                              </ul>
                              <ul style={{ margin: 0, listStyle: 'none' }}>
                                <li>
                                  <h6 style={{ fontSize: '11px', color: '#002a63', margin: 0 }}>STATUS</h6>
                                </li>
                                <li>
                                  <p style={{ color: '#696969', fontSize: '10px', marginTop: '5px', marginBottom: '22px', marginLeft: 0, marginRight: 0 }}>
                                    {data?.pmt_status || "-"}
                                  </p>
                                </li>
                              </ul>
                            </td>
                          </tr>
                          </tbody></table>
                      </td>
                    </tr>
                    </tbody></table>
                  <table id="table-2" style={{ width: '100%', textAlign: 'left', fontFamily: 'Arial, Helvetica, sans-serif', borderSpacing: 0, paddingTop: '10px', marginBottom: '50px' }}>
                    <tbody><tr style={{ backgroundColor: '#d3d3d3' }}>
                      <th style={{ fontSize: '11px', color: '#4d4d4d', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '5px', paddingRight: '20px', textDecoration: 'none' }}>SL.NO</th>
                      <th style={{ width: '310px', fontSize: '11px', color: '#4d4d4d', paddingTop: '10px', paddingBottom: '10px', paddingLeft: 0, paddingRight: '20px' }}>PARTICULARS</th>
                      <th style={{ fontSize: '11px', color: '#4d4d4d', paddingTop: '10px', paddingBottom: '10px', paddingLeft: 0, paddingRight: '20px' }}>RATE</th>
                      <th style={{ fontSize: '11px', color: '#4d4d4d', paddingTop: '10px', paddingBottom: '10px', paddingLeft: 0, paddingRight: '20px' }}>PERCENTAGE</th>
                      <th style={{ paddingTop: '10px', paddingBottom: '10px', paddingLeft: 0, paddingRight: '5px', fontSize: '11px', color: '#4d4d4d', textAlign: 'right' }}>AMOUNT</th>
                    </tr>
                      <tr>
                        <td style={{ fontSize: '11px', color: '#000', borderBottom: '2px solid #bbb', paddingTop: '10px', paddingRight: '20px', paddingBottom: '10px', paddingLeft: 0, textAlign: 'center' }}>1</td>
                        <td style={{ fontSize: '11px', color: '#000', borderBottom: '2px solid #bbb', paddingTop: '10px', paddingRight: '20px', paddingBottom: '10px', paddingLeft: 0 }}>Package: 
                          {data?.package ?? "-"}
                        </td>
                        <td style={{ fontSize: '11px', color: '#000', borderBottom: '2px solid #bbb', paddingTop: '10px', paddingRight: '20px', paddingBottom: '10px', paddingLeft: 0 }}>
                          {data?.custom_amount || data?.total_amount || "-"}
                        </td>
                        <td style={{ fontSize: '11px', color: '#000', borderBottom: '2px solid #bbb', paddingTop: '10px', paddingRight: '20px', paddingBottom: '10px', paddingLeft: 0 }} />
                        <td className="text-right" style={{ fontSize: '11px', color: '#000', borderBottom: '2px solid #bbb', paddingTop: '10px', paddingRight: 0, paddingBottom: '10px', paddingLeft: '10px', textAlign: 'right' }}>
                          {data?.custom_amount || data?.total_amount || "-"}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontSize: '11px', color: '#000', borderBottom: '2px solid #bbb', paddingTop: '10px', paddingRight: '20px', paddingBottom: '10px', paddingLeft: 0, textAlign: 'center' }}> 2 </td>
                        <td style={{ fontSize: '11px', color: '#000', borderBottom: '2px solid #bbb', paddingTop: '10px', paddingRight: '20px', paddingBottom: '10px', paddingLeft: 0 }} />
                        <td style={{ fontSize: '11px', color: '#000', borderBottom: '2px solid #bbb', paddingTop: '10px', paddingRight: '20px', paddingBottom: '10px', paddingLeft: 0 }} />
                        <td style={{ fontSize: '11px', color: '#000', borderBottom: '2px solid #bbb', paddingTop: '10px', paddingRight: '20px', paddingBottom: '10px', paddingLeft: 0 }} />
                        <td style={{ fontSize: '11px', color: '#000', borderBottom: '2px solid #bbb', paddingTop: '10px', paddingRight: 0, paddingBottom: '10px', paddingLeft: '10px', textAlign: 'right' }} />
                      </tr>
                      <tr>
                        <td style={{ fontSize: '11px', color: '#000', borderBottom: '2px solid #bbb', paddingTop: '10px', paddingRight: '20px', paddingBottom: '10px', paddingLeft: 0, textAlign: 'center' }}> 3 </td>
                        <td style={{ fontSize: '11px', color: '#000', borderBottom: '2px solid #bbb', paddingTop: '10px', paddingRight: '20px', paddingBottom: '10px', paddingLeft: 0 }} />
                        <td style={{ fontSize: '11px', color: '#000', borderBottom: '2px solid #bbb', paddingTop: '10px', paddingRight: '20px', paddingBottom: '10px', paddingLeft: 0 }} />
                        <td style={{ fontSize: '11px', color: '#000', borderBottom: '2px solid #bbb', paddingTop: '10px', paddingRight: '20px', paddingBottom: '10px', paddingLeft: 0 }} />
                        <td style={{ fontSize: '11px', color: '#000', borderBottom: '2px solid #bbb', paddingTop: '10px', paddingRight: 0, paddingBottom: '10px', paddingLeft: '10px', textAlign: 'right' }} />
                      </tr>
                      <tr>
                        <td style={{ borderBottom: 0, fontSize: '11px', color: '#000', paddingTop: '10px', paddingRight: '20px', paddingBottom: '10px', paddingLeft: 0 }} />
                        <th style={{ borderBottom: '2px solid #002a63', fontSize: '11px', color: '#4d4d4d', paddingTop: '10px', paddingBottom: '10px', paddingLeft: 0, paddingRight: '20px' }}>GST @ 18.00%</th>
                        <td style={{ borderColor: '#002a63', fontSize: '11px', color: '#000', borderBottom: '2px solid #002a63', paddingTop: '10px', paddingRight: '20px', paddingBottom: '10px', paddingLeft: 0 }} />
                        <td style={{ borderColor: '#002a63', fontSize: '11px', color: '#000', borderBottom: '2px solid #002a63', paddingTop: '10px', paddingRight: '20px', paddingBottom: '10px', paddingLeft: 0 }}>18.00 %</td>
                        <td style={{ borderColor: '#002a63', fontSize: '11px', color: '#000', borderBottom: '2px solid #002a63', paddingTop: '10px', paddingRight: 0, paddingBottom: '10px', paddingLeft: '10px', textAlign: 'right' }}>
                          {data?.custom_amount ? data?.custom_amount * (18 / 100) : data?.total_amount ? data?.total_amount * (18 / 100) : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ borderBottom: 0, fontSize: '11px', color: '#000', paddingTop: '10px', paddingRight: '20px', paddingBottom: '10px', paddingLeft: 0 }} />
                        <th style={{ borderBottom: 0, color: '#002a63', fontSize: '11px', paddingTop: '10px', paddingBottom: '10px', paddingLeft: 0, paddingRight: '20px' }}>NET AMOUNT</th>
                        <td style={{ borderBottom: 0, fontSize: '11px', color: '#000', paddingTop: '10px', paddingRight: '20px', paddingBottom: '10px', paddingLeft: 0 }} />
                        <td style={{ borderBottom: 0, fontSize: '11px', color: '#000', paddingTop: '10px', paddingRight: '20px', paddingBottom: '10px', paddingLeft: 0 }} />
                        <th style={{ borderBottom: 0, fontSize: '11px', color: '#002a63', paddingRight: 0, textAlign: 'right' }}>
                          {data?.custom_amount ? data?.custom_amount + data?.custom_amount * (18 / 100) : data?.total_amount ? data?.total_amount + data?.total_amount * (18 / 100) : "-"}
                        </th>
                      </tr>
                    </tbody></table>
                  <table style={{ borderSpacing: 0, width: '50px', height: '2px', backgroundColor: '#002a63' }}>
                    <tbody><tr>
                      <td />
                    </tr>
                    </tbody></table>
                  <table id="footer" style={{ borderSpacing: 0, color: '#000', fontSize: '10px', width: '100%' }}>
                    <tbody><tr>
                      <td>
                        <ul style={{ margin: 0, paddingTop: '5px', paddingBottom: 0, paddingLeft: 0, paddingRight: 0, listStyle: 'none' }}>
                          <li style={{ marginTop: '3px', marginBottom: 0, marginLeft: 0, marginRight: 0 }}>
                            Make all cheques payable to <b>PERFECT HANDS SOLUTIONS PVT.LTD.</b> &amp; Thank you for your business!
                          </li>
                          <li style={{ marginTop: '10px', marginBottom: 0, marginLeft: 0, marginRight: 0 }}>
                            <table>
                              <tbody><tr>
                                <td>Bank Name</td>
                                <td><b>: ICICI BANK LTD</b></td>
                              </tr>
                                <tr>
                                  <td>Account Name</td>
                                  <td><b>: Perfect Hands Solutions Pvt.Ltd.</b></td>
                                </tr>
                                <tr>
                                  <td>Account Number</td>
                                  <td><b>: 116205500064</b></td>
                                </tr>
                                <tr>
                                  <td>Branch of Bank</td>
                                  <td><b>: Ravipuram, Cochin</b></td>
                                </tr>
                                <tr>
                                  <td>Bank Account Type</td>
                                  <td><b>: Current Account CSB</b></td>
                                </tr>
                                <tr>
                                  <td>IFSC Code</td>
                                  <td><b>: ICIC0001162</b></td>
                                </tr>
                                <tr>
                                  <td>Pin Code of Bank</td>
                                  <td><b>: 682 015</b></td>
                                </tr>
                              </tbody></table>
                          </li>
                        </ul>
                      </td>
                      <td id="company-id" style={{ textAlign: 'right', verticalAlign: 'bottom' }}>
                        For Perfect Hands Solutions Pvt.ltd.
                      </td>
                    </tr>
                    </tbody></table>
                </td>
              </tr>
              </tbody>
            </table>
          </td>
        </tr>
        </tbody>
      </table>
    </>
  );
};

export default InvoiceTemplate;