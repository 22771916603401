import React, { useState } from "react";
import { Box, Button, CircularProgress, InputAdornment, MenuItem, Stack, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { CssTextField } from "../../components/TextField";
import { Build, Close } from "@mui/icons-material";
import { tokens } from "../../theme";
import { toast } from "react-toastify";
import Header from "../../components/Header";
import { updateInvoice } from "../../store/actions";
import moment from "moment";

function UpdatePaymentStatus({ invoiceDetails, setUpdateStatusModal, fetchData }) {
    const theme = useTheme();
    const dispatch = useDispatch();
    const colors = tokens(theme.palette.mode);
    const [selectedStatus, setSelectedStatus] = useState(invoiceDetails?.pmt_status);
    const [partialAmt, setPartialAmt] = useState("");
    const [pmtDate, setPmtDate] = useState(moment().format("YYYY-MM-DD"));
    const max = invoiceDetails?.custom_amount
        ? invoiceDetails?.custom_amount + invoiceDetails?.custom_amount * (18 / 100)
        : invoiceDetails?.total_amount
        ? invoiceDetails?.total_amount + invoiceDetails?.total_amount * (18 / 100)
        : 0;
    const { isLoading } = useSelector(({ loading }) => ({ isLoading: loading }));

    const modalBoxStyle = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        maxHeight: "90vh",
        width: { sm: 400, xs: "90%" },
        bgcolor: colors.primary[400],
        outline: "none",
        boxShadow: 24,
        p: 2,
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let res = await dispatch(updateInvoice({
            invNo: invoiceDetails?.inv_no,
            pmtStatus: selectedStatus,
            partialAmount: partialAmt,
            paymentDate: moment(pmtDate, "YYYY-MM-DD").unix(),
        }))

        if (res) {
            fetchData()
            toast.success("Status updated");
            setUpdateStatusModal(false);
        }
    };

    return (
        <Box sx={modalBoxStyle}>
            <Stack direction="row" justifyContent="space-between">
                <Header title="UPDATE PAYMENT STATUS" titleVariant="h4" />
                <Close onClick={() => setUpdateStatusModal(false)} sx={{ cursor: "pointer" }} />
            </Stack>
            <form onSubmit={handleSubmit}>
                <Stack spacing={2}>
                    <CssTextField
                        fullWidth
                        select
                        value={selectedStatus || "no_preference"}
                        label="Payment status"
                        onChange={(e) => setSelectedStatus(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Build />
                                </InputAdornment>
                            ),
                        }}
                    >
                        <MenuItem value={"paid"}>{"Paid"}</MenuItem>
                        <MenuItem value={"unpaid"}>{"Unpaid"}</MenuItem>
                        <MenuItem value={"partial_pmt"}>{"Partial"}</MenuItem>
                    </CssTextField>
                    {selectedStatus === "partial_pmt" ? (
                        <CssTextField
                            fullWidth
                            required={selectedStatus === "partial_pmt"}
                            value={partialAmt}
                            type="number"
                            label="Partial amount"
                            onChange={(e) => setPartialAmt(e.target.value)}
                            inputProps={{ min: 1, max: max }}
                        />
                    ) : null}
                    {selectedStatus === "partial_pmt" || selectedStatus === "paid" ? (
                        <CssTextField
                            fullWidth
                            required={selectedStatus === "partial_pmt" || selectedStatus === "paid"}
                            type="date"
                            label="Payment Date"
                            value={pmtDate}
                            onChange={(e) => setPmtDate(e.target.value)}
                        />
                    ) : null}
                    <Button
                        fullWidth
                        variant="contained"
                        type="submit"
                        disabled={invoiceDetails?.status === selectedStatus}
                    >
                        {isLoading ? <CircularProgress color="inherit" size={24} /> : <b>Update</b>}
                    </Button>
                </Stack>
            </form>
        </Box>
    );
}

export default UpdatePaymentStatus;
