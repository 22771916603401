import { post } from "../../services";
import {
    ACTIVATE_HOTEL,
    ADD_NEW_HOTEL,
    ADD_NEW_ROOM,
    LIST_ALL_BOOKINGS,
    LIST_ALL_HOTELS,
    LIST_ALL_HOTEL_LOGS,
    LIST_ALL_INVOICES,
    LIST_ALL_ROOMS,
    UPDATE_HOTEL_CONNECTED,
    UPDATE_HOTEL_DEPENDENCY,
    UPDATE_HOTEL_REVENUE_MANAGER,
    UPDATE_INVOICE,
} from "../../graphql";
import {
    RESET_ALL_BOOKINGS,
    RESET_ALL_HOTELS_LIST,
    RESET_ALL_HOTEL_LOGS,
    RESET_ALL_INVOICES,
    RESET_ALL_ROOMS_LIST,
    SAVE_ALL_BOOKINGS,
    SAVE_ALL_HOTELS_LIST,
    SAVE_ALL_HOTEL_LOGS,
    SAVE_ALL_INVOICES,
    SAVE_ALL_ROOMS_LIST,
    TOGGLE_LOADING,
} from "../types";

export const addNewRoom = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: ADD_NEW_ROOM,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            dispatch(resetAllRoomsList());
            dispatch(listAllRooms());
            return true;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const listAllRooms = (requestData) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });
        const {
            rooms: { pageSize, pageNumber, list },
        } = getState().admin;

        let apiResponse = await post("", {
            query: LIST_ALL_ROOMS,
            variables: {
                pageSize: pageSize,
                pageNumber: pageNumber,
                ...requestData,
            },
        });

        if (apiResponse.data.customStatus) {
            let {
                list_all_rooms: { data, hasMore },
            } = apiResponse.data.data;
            let updatedList = [...list, ...data];
            dispatch({
                type: SAVE_ALL_ROOMS_LIST,
                payload: {
                    list: updatedList,
                    showViewMore: hasMore,
                    pageNumber: pageNumber + 1,
                },
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetAllRoomsList = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_ALL_ROOMS_LIST });
    };
};

export const addNewHotel = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: ADD_NEW_HOTEL,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            dispatch(resetAllHotelsList());
            dispatch(listAllHotels());
            return true;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const listAllHotels = (requestData) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });
        const {
            hotels: { pageSize, pageNumber, list },
        } = getState().admin;

        let apiResponse = await post("", {
            query: LIST_ALL_HOTELS,
            variables: {
                pageSize: pageSize,
                pageNumber: pageNumber,
                ...requestData,
            },
        });

        if (apiResponse.data.customStatus) {
            let {
                list_all_hotels: { data, hasMore },
            } = apiResponse.data.data;
            let updatedList = [...list, ...data];
            dispatch({
                type: SAVE_ALL_HOTELS_LIST,
                payload: {
                    list: updatedList,
                    showViewMore: hasMore,
                    pageNumber: pageNumber + 1,
                },
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetAllHotelsList = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_ALL_HOTELS_LIST });
    };
};

export const listAllHotelLogs = (requestData) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });
        const {
            hotelLogs: { pageSize, pageNumber },
        } = getState().admin;

        let apiResponse = await post("", {
            query: LIST_ALL_HOTEL_LOGS,
            variables: {
                ...requestData,
                pageSize: requestData?.pageSize || pageSize,
                pageNumber: requestData?.pageNumber || pageNumber,
            },
        });

        if (apiResponse.data.customStatus) {
            let {
                list_all_hotel_logs: { data, hasMore, count },
            } = apiResponse.data.data;
            // let updatedList = [...list, ...data];
            dispatch({
                type: SAVE_ALL_HOTEL_LOGS,
                payload: {
                    list: data,
                    showViewMore: hasMore,
                    pageSize: requestData?.pageSize || pageSize,
                    pageNumber: requestData?.pageNumber || pageNumber,
                    count: count,
                },
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetAllHotelLogs = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_ALL_HOTEL_LOGS });
    };
};

export const listAllBookings = (requestData) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });
        const {
            bookings: { pageSize, pageNumber },
        } = getState().admin;

        let apiResponse = await post("", {
            query: LIST_ALL_BOOKINGS,
            variables: {
                ...requestData,
                pageSize: requestData?.pageSize || pageSize,
                pageNumber: requestData?.pageNumber || pageNumber,
            },
        });

        if (apiResponse.data.customStatus) {
            let {
                list_all_bookings: { data, hasMore, count },
            } = apiResponse.data.data;
            // let updatedList = [...list, ...data];
            dispatch({
                type: SAVE_ALL_BOOKINGS,
                payload: {
                    list: data,
                    showViewMore: hasMore,
                    pageSize: requestData?.pageSize || pageSize,
                    pageNumber: requestData?.pageNumber || pageNumber,
                    count: count,
                },
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetAllBookings = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_ALL_BOOKINGS });
    };
};

export const listAllInvoices = (requestData) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });
        const {
            invoices: { pageSize, pageNumber },
        } = getState().admin;

        let apiResponse = await post("", {
            query: LIST_ALL_INVOICES,
            variables: {
                ...requestData,
                pageSize: requestData?.pageSize || pageSize,
                pageNumber: requestData?.pageNumber || pageNumber,
            },
        });

        if (apiResponse.data.customStatus) {
            let {
                list_all_invoices: { data, count },
            } = apiResponse.data.data;

            dispatch({
                type: SAVE_ALL_INVOICES,
                payload: {
                    list: data,
                    showViewMore: false,
                    pageSize: requestData?.pageSize || pageSize,
                    pageNumber: requestData?.pageNumber || pageNumber,
                    count: count,
                },
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetAllInvoices = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_ALL_INVOICES });
    };
};

export const updateInvoice = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: UPDATE_INVOICE,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            // dispatch(resetAllInvoices());
            // dispatch(listAllInvoices());
            dispatch({ type: TOGGLE_LOADING, payload: false });
            return true;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const updateHotelDependency = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: UPDATE_HOTEL_DEPENDENCY,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            // dispatch(resetAllHotelsList());
            // dispatch(listAllHotels());
            dispatch({ type: TOGGLE_LOADING, payload: false });
            return true;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const updateHotelConnectedRooms = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: UPDATE_HOTEL_CONNECTED,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            dispatch({ type: TOGGLE_LOADING, payload: false });
            return true;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const updateHotelRevenueManager = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: UPDATE_HOTEL_REVENUE_MANAGER,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            dispatch({ type: TOGGLE_LOADING, payload: false });
            return true;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const activateHotel = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: ACTIVATE_HOTEL,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            dispatch({ type: TOGGLE_LOADING, payload: false });
            return true;
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};