import React, { useEffect, useState } from "react";
import { 
    Box, Button, IconButton, Typography, TextField, Checkbox, useTheme ,
    TableBody, TableCell, TableContainer, TableHead, TableRow, Divider, 
    MenuItem, Popover, Stack, Table, Grid,
} from "@mui/material";
import { 
    ArrowBackIosNew, ArrowForward, ArrowForwardIos, Close,
} from "@mui/icons-material";
import { authorizedPost, toggleLoading } from "../../store/actions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LIST_ALL_CALENDAR_INVOICES } from "../../graphql";
import { PaymentStatusChip } from "../../components/Chips";
import { tokens } from "../../theme";
import { toast } from "react-toastify";
import Header from "../../components/Header";
import moment from "moment";
import "./style.css"

const years = (num = 10) => {
    const year = new Date(moment().add(5, "year")).getFullYear();
    return Array.from({ length: num }, (v, i) => year - num + i + 1);
}

const InvoiceCalendar = () => {
    const d = new Date();
    const [year, setYear] = useState(localStorage.getItem("year") || d.getFullYear());
    const theme = useTheme();
    const navigate = useNavigate();
    const colors = tokens(theme.palette.mode);
    const dispatch = useDispatch();
    const [selection, setSelection] = useState(false);
    const [selectedMonths, setSelectedMonths] = useState([]);
    const [selectedHotelId, setSelectedHotelId] = useState("");
    const [search, setSearch] = useState("");
    const [invoices, setInvoices] = useState([]);
    const [filtered, setFiltered] = useState([]);
    const [invDetails, setInvDetails] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const rows = search !== "" ? filtered : invoices;

    const handleClick = (event, prop) => {
        setInvDetails({
            ...prop,
        }) 
        setAnchorEl(event.currentTarget);
    };
// console.log(invDetails);
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        document.title = 'Calendar';
            dataFetch();
    // eslint-disable-next-line
    }, [dispatch]);

    useEffect(() => {
        if (search !== "") {
            var filt = invoices?.filter((e) => e?.name?.indexOf(search?.toLowerCase()) > -1);
            setFiltered(filt);
        }
    }, [search, invoices]);

    const dataFetch = async(y = year) => {
        dispatch(toggleLoading(true));
        const result = await authorizedPost(LIST_ALL_CALENDAR_INVOICES, {year: y});
        if(result?.status){
            const { list_all_calendar_invoices: { data } } = result?.data;
            setInvoices(data);
        }
        dispatch(toggleLoading(false));
    }

    const handleHotelSelection = (hotelId) => {
        if(hotelId === selectedHotelId){
            setSelectedHotelId("");
        } else {
            setSelectedHotelId(hotelId);
        }
        setSelectedMonths([]);
    }

    const handleMonthSelection = (month) => {
        let months = selectedMonths;
        if (months?.includes(month)) {
            let index = months?.indexOf(month);
            months[index] = undefined;
            months = months.filter(item => item !== undefined );
        } else {
            months = [].concat(months, month);
        }
        setSelectedMonths(months);
    }

    const handleContinueBtn = () => {
        localStorage.setItem("selectedHotelId", JSON.stringify(selectedHotelId));
        localStorage.setItem("selectedMonths", JSON.stringify(selectedMonths));
        setSelection(selection ? false : true);
        navigate("/create-invoice");
    }

    const ColorCode = ({ size = 15, color, label }) => (
        <Grid item>
            <Stack direction="row" spacing={1} alignItems="center">
                <Box width={size} height={size} bgcolor={color} />
                <Typography>{label}</Typography>
            </Stack>
        </Grid>
    )

    return (
        <Box m="20px" mb={15}>
            {/* <Container disableGutters> */}
            <Stack direction={"row"} justifyContent="space-between">
                <Header title="CALENDAR" subtitle="Invoice Calendar Page" />
                <Stack alignItems="end">
                    <Grid container mb={1} spacing={1} justifyContent="flex-end">
                        <ColorCode color={"#4BB543"} label={"Available"} />
                        <ColorCode color={"#ff9800"} label={"Created"} />
                    </Grid>
                    <Grid container spacing={1} justifyContent="flex-end">
                        <ColorCode size={10} color={"#00e1ff"} label={"Paid"} />
                        <ColorCode size={10} color={"#d400ff"} label={"Unpaid"} />
                        <ColorCode size={10} color={"#aaabff"} label={"Partial"} />
                    </Grid>
                </Stack>
            </Stack>
            <Stack
                spacing={2}
                direction={{ md: "row", xs: "column-reverse" }}
                alignItems={{ md: "flex-end", xs: "normal" }}
                justifyContent={"space-between"}
            >
                <Stack direction="row" justifyContent="space-between" spacing={1}>
                    <Stack>
                        <TextField
                            size="small"
                            variant="outlined"
                            label="Search by hotel"
                            value={search}
                            onChange={(e) => setSearch(e?.target?.value)}
                            sx={{ width: 150 }}
                        />
                    </Stack>
                    <Stack direction="row" spacing={1} justifyContent="flex-end">
                        <Box display="flex" justifyContent="flex-end">
                            <Button
                                size="small"
                                variant="contained"
                                endIcon={!selection ? <ArrowForward /> : ""}
                                disabled={localStorage.getItem("accountType") !== "admin"}
                                onClick={() => {
                                    setSelectedMonths([])
                                    setSelectedHotelId("")
                                    setSelection(selection ? false : true)
                                }}
                            >
                                {selection ? <Close fontSize='small' /> : <b>Create invoice</b>}
                            </Button>
                        </Box>
                        {selection &&
                            <Box display="flex" justifyContent="flex-end">
                                <Button
                                    size="small"
                                    variant="contained"
                                    endIcon={<ArrowForward />}
                                    disabled={!selectedMonths?.length}
                                    onClick={handleContinueBtn}
                                >
                                    <b>Continue</b>
                                </Button>
                            </Box>
                        }
                    </Stack>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={1}>
                    <IconButton onClick={() => {
                        let y = year - 1;
                        dataFetch(y)
                        setYear(y)
                        localStorage.setItem("year", y)
                    }}>
                        <ArrowBackIosNew />
                    </IconButton>
                    <TextField
                        select
                        size="small"
                        variant="standard"
                        value={year}
                        onChange={(e) => {
                            dataFetch(e.target.value)
                            setYear(e.target.value)
                            localStorage.setItem("year", e.target.value)
                        }}
                    >
                        {years()?.map((y) => (
                            <MenuItem value={y} key={y}>
                                {y}
                            </MenuItem>
                        ))}
                    </TextField>
                    <IconButton onClick={() => {
                        let y = parseInt(year) + 1;
                        dataFetch(y)
                        setYear(y)
                        localStorage.setItem("year", y)
                    }}>
                        <ArrowForwardIos />
                    </IconButton>
                </Stack>
            </Stack>

                {rows?.length ?
                    // <Grid container>
                    //     <Grid item sm={12} md={12} lg={12}>
                    //         <div className="table-wrapper">
                    <div className="reports" style={{ backgroundColor: colors.primary[400] }}>
                        <TableContainer>
                            <Box component={Table} alignItems="center">
                                {/* <Table> */}
                                <TableHead>
                                    <TableRow>
                                    <th style={{ backgroundColor: colors.blueAccent[700] }}>Date</th>
                                    {rows[0]?.data?.map((item, index) => {
                                        return (
                                            <th key={index} style={{
                                                color: `${item?.month}-${item?.year}` === moment().format("MMM-YYYY") ? "yellow" : "",
                                                backgroundColor: colors.blueAccent[700],
                                            }}>
                                                {`${item?.month}, ${item?.year}`}
                                            </th>
                                        )
                                    })}
                                    </TableRow>
                                </TableHead>
                                {rows?.map((item, index) => {
                                    return (
                                        <TableBody key={index}>
                                            <TableRow>
                                                <TableCell 
                                                    style={{ backgroundColor: selection && !item?.package ? "grey" : colors.greenAccent[700] }} 
                                                    className="typename"
                                                    onClick={selection ? () => {
                                                        if(item?.package) handleHotelSelection(item?._id)
                                                        else return toast.warning("Unable to select, No package linked to this property!")
                                                    } : null}
                                                >
                                                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                                                        <Typography fontWeight={600} width={150} noWrap textTransform="capitalize">
                                                            {item?.name}
                                                        </Typography>
                                                        <Box width={20}>
                                                            {selection && (
                                                                <Checkbox sx={{ p: 0 }} size="small" checked={selectedHotelId === item?._id} disabled={!item?.package}/>
                                                            )}
                                                        </Box>
                                                    </Stack>
                                                </TableCell>

                                                {item?.data?.map((prop, ind) => {
                                                    if (prop?.invoice) {
                                                        return (
                                                            <TableCell key={ind} className="bkd" onClick={(el) => handleClick(el, prop)}>
                                                                <Stack direction={"row"} spacing={1} justifyContent="space-between">
                                                                    <Box
                                                                        title="expand" 
                                                                        overflow="hidden"
                                                                        width={40}
                                                                        className={
                                                                            prop?.invoice?.invoice_id?.pmt_status === "paid" ? "paid" : 
                                                                            prop?.invoice?.invoice_id?.pmt_status === "unpaid" ? "unpaid" :
                                                                            prop?.invoice?.invoice_id?.pmt_status === "partial_pmt" ? "partial" : ""
                                                                        }
                                                                    >
                                                                        <Typography fontSize={12} p={0} noWrap>
                                                                            <small>{prop?.invoice?.invoice_id?.inv_no}</small>
                                                                        </Typography>
                                                                    </Box>
                                                                </Stack>
                                                            </TableCell>
                                                        )
                                                    } else {
                                                        return(
                                                            <TableCell 
                                                                key={ind} 
                                                                className="avl"
                                                                style={{
                                                                    cursor: selection && selectedHotelId === item?._id ? "pointer" : "",
                                                                    backgroundColor: selectedHotelId === item?._id && selectedMonths.includes(`${prop?.month}-${prop?.year}`) ? "green" : "",
                                                                }}
                                                                onClick={selection && selectedHotelId === item?._id ? () => {
                                                                    if(item?.rate_per_night){
                                                                        if(moment(`01-${prop?.month}-${prop?.year}`, "DD-MMM-YYYY").isBefore(moment().startOf("month"))){
                                                                            handleMonthSelection(`${prop?.month}-${prop?.year}`, item?._id);
                                                                        } 
                                                                        else return toast.warning("Only previous months can be selected for this property!");
                                                                    } else {
                                                                        handleMonthSelection(`${prop?.month}-${prop?.year}`, item?._id);
                                                                    }
                                                                } : null}
                                                            >
                                                                <Box width={20}>
                                                                    {selection && selectedHotelId === item?._id && (
                                                                        <Checkbox sx={{ p: 0 }} size="small" checked={selectedMonths.includes(`${prop?.month}-${prop?.year}`)} />
                                                                    )}
                                                                </Box>
                                                            </TableCell>
                                                        )
                                                    }
                                                })}
                                            </TableRow>
                                        </TableBody>
                                    )
                                })}
                            </Box>
                        </TableContainer>
                    </div>
                    :
                    <Stack
                        textAlign="center"
                        height="200px"
                        justifyContent="center"
                    >
                        No data found...
                    </Stack>
                }
            {/* </Container> */}

            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Box minWidth={300}>
                    <Box
                        p={1}
                        textAlign="center"
                        fontWeight={600}
                        bgcolor={colors.blueAccent[700]}
                    >
                        {invDetails?.invoice?.invoice_id?.inv_no}
                    </Box>
                    <Stack p={1} width={300}>
                        <Stack p={1} direction="row" spacing={2} justifyContent="space-between" alignItems="start">
                            <Typography width={"70%"}>
                                Remarks :
                            </Typography>
                            <Typography textAlign="end">
                                {invDetails?.invoice?.remarks || "-"}
                            </Typography>
                        </Stack>
                        <Divider />
                        <Stack p={1} direction="row" spacing={2} justifyContent="space-between" alignItems="start">
                            <Typography width={"70%"}>
                                {invDetails?.invoice?.invoice_id?.custom_amount ? "Custom amount" : "Total amount"} :
                            </Typography>
                            <Typography>
                                {invDetails?.invoice?.invoice_id?.custom_amount ? 
                                    invDetails?.invoice?.invoice_id?.custom_amount : 
                                    invDetails?.invoice?.invoice_id?.total_amount ?? "-"
                                }
                            </Typography>
                        </Stack>
                        <Divider />
                        {/* <Stack p={1} direction="row" spacing={2} justifyContent="space-between" alignItems="start">
                            <Typography width={"70%"}>
                                Custom amount:
                            </Typography>
                            <Typography>
                                {invDetails?.invoice?.invoice_id?.custom_amount ?? "-"}
                            </Typography>
                        </Stack>
                        <Divider /> */}
                        <Stack p={1} direction="row" spacing={2} justifyContent="space-between" alignItems="start">
                            <Typography width={"70%"}>
                                Payment status :
                            </Typography>
                            <PaymentStatusChip status={invDetails?.invoice?.invoice_id?.pmt_status} />
                        </Stack>
                        <Button
                            size="small"
                            variant="contained"
                            onClick={() => navigate(`/invoice-details/${invDetails?.invoice?.invoice_id?.inv_no}`)}
                        >
                            <b>View more details</b>
                        </Button>
                    </Stack>
                </Box>
            </Popover>
        </Box>
    );
};

export default InvoiceCalendar;
