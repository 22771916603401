export const TOGGLE_LOADING = "TOGGLE_LOADING";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT = "LOGOUT";

export const SAVE_ALL_HIGHLIGHTS = "SAVE_ALL_HIGHLIGHTS";
export const RESET_ALL_HIGHLIGHTS_DATA = "RESET_ALL_HIGHLIGHTS_DATA";

export const SAVE_ALL_FACILITIES = "SAVE_ALL_FACILITIES";
export const RESET_ALL_FACILITIES_DATA = "RESET_ALL_FACILITIES_DATA";

export const SAVE_ALL_BOOKINGS = "SAVE_ALL_BOOKINGS";
export const RESET_ALL_BOOKINGS = "RESET_ALL_BOOKINGS";

export const SAVE_ALL_ROOMS_LIST = "SAVE_ALL_ROOMS_LIST";
export const RESET_ALL_ROOMS_LIST = "RESET_ALL_ROOMS_LIST";

export const SAVE_ALL_HOTELS_LIST = "SAVE_ALL_HOTELS_LIST";
export const RESET_ALL_HOTELS_LIST = "RESET_ALL_HOTELS_LIST";

export const SAVE_ALL_PACKAGES = "SAVE_ALL_PACKAGES";
export const RESET_ALL_PACKAGES = "RESET_ALL_PACKAGES";

export const SAVE_ALL_HOTEL_PACKAGES = "SAVE_ALL_HOTEL_PACKAGES";
export const RESET_ALL_HOTEL_PACKAGES = "RESET_ALL_HOTEL_PACKAGES";

export const SAVE_ALL_HOTEL_LOGS = "SAVE_ALL_HOTEL_LOGS";
export const RESET_ALL_HOTEL_LOGS = "RESET_ALL_HOTEL_LOGS";

export const SAVE_ALL_INVOICES = "SAVE_ALL_INVOICES";
export const RESET_ALL_INVOICES = "RESET_ALL_INVOICES";