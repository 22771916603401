import React, { useEffect, useRef, useState } from "react";
import { Autocomplete, Box, IconButton, InputAdornment, Stack, TextField, Typography, useTheme } from "@mui/material";
import { listAllBookings, listAllHotels, resetAllBookings, resetAllHotelsList } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { tableStyle } from "../mui-styles/table";
import { StatusChip } from "../../components/Chips";
import { PaginationFooter } from "../../components";
import { CalendarMonth, Download } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import CsvDownloader from "react-csv-downloader";
import Header from "../../components/Header";
import moment from "moment";

const Transactions = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const dispatch = useDispatch();
    const startInputRef = useRef();
    const endInputRef = useRef();
    const bookedAtInputRef = useRef();
    const [hotelId, setHotelId] = useState("");
    const [startDate, setStartDate] = useState(0);
    const [endDate, setEndDate] = useState(0);
    const [bookedAt, setBookedAt] = useState(0);

    useEffect(() => {
        dispatch(resetAllHotelsList());
        dispatch(listAllHotels());
    }, [dispatch])

    useEffect(() => {
        dispatch(resetAllBookings());
        fetchMoreData();
    // eslint-disable-next-line
    }, [hotelId, bookedAt, startDate, endDate]);

    const fetchMoreData = (page) => {
        dispatch(listAllBookings({ hotelId: hotelId === "all" ? "" : hotelId, ...page, bookedAt, startDate, endDate }));
    }

    const { bookingsList, hotels, pageNumber, pageSize, count } = useSelector(
        ({
            admin: {
                bookings: { list, pageNumber, pageSize, count },
                hotels,
            },
        }) => ({
            bookingsList: list,
            pageNumber, pageSize, count,
            hotels,
        })
    );

    const optionsList = hotels?.list?.map((el, idx) => ({
        label: el.name,
        id: el._id,
    }))

    const CustomFooter = () => (
        <PaginationFooter
            count={count}
            pageSize={pageSize}
            pageNumber={pageNumber}
            fetchMoreData={(page) => fetchMoreData(page)}
        />
    );

    const generateCsvDownloadData = (data) => {
        let filteredData = data.map((row) => (
            {
                "Transaction id": row.order?.transaction_id,
                "Date": moment(new Date(row.order?.updatedAt)).format("DD-MMM-YYYY h:mm A"),
                "Amount": row.total_price,
                "Guest name": row.guest_name,
                "Guest email": row.guest_email,
                "Check-in date": moment.unix(row.checkin_date).format("YYYY-MM-DD"),
                "Check-out date": moment.unix(row.checkout_date).format("YYYY-MM-DD"),
                "Hotel": row.hotel_id?.name,
                "Booking from": row.booking_from,
                "Status": row.order?.status,
            }
        ));
        return filteredData;
    };

    const columns = [
        {
            field: "order",
            headerName: "Transaction id",
            width: 150,
            renderCell: ({row: { order }}) => (<small>{order?.transaction_id}</small>),
        },
        {
            field: "updatedAt",
            headerName: "Date",
            width: 180,
            renderCell: ({row: { order }}) => (<small>{moment(new Date(order?.updatedAt)).format("DD-MMM-YYYY h:mm A")}</small>),
        },
        { 
            field: "total_price", 
            headerName: "Amount", 
            width: 100,
            renderCell: ({ row: { custom_price, total_price } }) => `${custom_price ? custom_price : total_price}`,
        },
        {
            field: "guest_name",
            headerName: "Guest info",
            width: 220,
            renderCell: ({ row: { guest_name, guest_email } }) => {
                return (
                    <Stack>
                        <Typography color={colors.greenAccent[400]}>
                            <b>{guest_name}</b>
                        </Typography>
                        <Typography fontSize={"small"}>
                            {guest_email || ""}
                        </Typography>
                    </Stack>
                )
            },
        },
        {
            field: "checkin_date",
            headerName: "Check-in date",
            width: 130,
            valueGetter: (params) => `${params.row.checkin_date ? moment.unix(params.row.checkin_date).format("YYYY-MM-DD") : "-"}`,
        },
        {
            field: "checkout_date",
            headerName: "Check-out date",
            width: 130,
            valueGetter: (params) => `${params.row.checkout_date ? moment.unix(params.row.checkout_date).format("YYYY-MM-DD") : "-"}`,
        },
        { 
            field: "hotel_id", 
            headerName: "Hotel", 
            width: 150,
            renderCell: ({ row: { hotel_id } }) => hotel_id?.name ?? "-", 
        },
        { 
            field: "booking_from", 
            headerName: "Booking from", 
            width: 150,
            renderCell: ({ row: { booking_from } }) => `${booking_from?.replace("_", " ")}`, 
        },
        {
            field: "status",
            headerName: "Status",
            width: 120,
            renderCell: ({ row: { order } }) => <StatusChip status={order?.status} />,
        },
    ];

    return (
        <Box m="20px" mb={15}>
            <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                m="20px 0"
            >
                <Header title="TRANSACTIONS" subtitle="welcome to Transactions" />
            </Box>
            <Box display="flex" flexDirection={{ xs: "column", md: "row" }} gap={1}>
                <Stack direction={"row"} spacing={1} flexGrow={1}>
                    <TextField
                        fullWidth
                        size="small"
                        type="date"
                        label="Start Date"
                        placeholder="Start Date"
                        sx={{ width: 200 }}
                        inputProps={{ min: endDate ? moment.unix(endDate).subtract(1, "month").format("YYYY-MM-DD") : "" }}
                        value={startDate ? moment.unix(startDate).format("YYYY-MM-DD") : ""}
                        onChange={(newValue) => {
                            setStartDate(moment(newValue.target.value, "YYYY-MM-DD").startOf("day").unix())
                            if (endDate !== "" && moment(newValue.target.value).add(1, "days").format("YYYY-MM-DD") > moment.unix(endDate).format("YYYY-MM-DD")) {
                                setEndDate(moment(newValue.target.value, "YYYY-MM-DD").add(1, "days").endOf("day").unix());
                            }
                            if(!newValue.target.value) {
                                setStartDate(0)
                                setEndDate(0)
                            }
                        }}
                        inputRef={startInputRef}
                        onClick={() => startInputRef.current.showPicker()}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <CalendarMonth sx={{ cursor: "pointer" }} />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        fullWidth
                        size="small"
                        type="date"
                        label="End Date"
                        placeholder="End Date"
                        sx={{ width: 200 }}
                        inputProps={{ min: moment.unix(startDate).format("YYYY-MM-DD"), max: startDate ? moment.unix(startDate).add(1, "month").format("YYYY-MM-DD") : "" }}
                        value={endDate ? moment.unix(endDate).format("YYYY-MM-DD") : ""}
                        onChange={(newValue) => {
                            setEndDate(moment(newValue.target.value, "YYYY-MM-DD").endOf("day").unix())
                            if(!newValue.target.value) {
                                setStartDate(0)
                                setEndDate(0)
                            }
                        }}
                        inputRef={endInputRef}
                        onClick={() => endInputRef.current.showPicker()}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <CalendarMonth sx={{ cursor: "pointer" }} />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Stack>
                <Stack direction={"row"} spacing={1}>
                    <Autocomplete
                        // disablePortal
                        id="combo-box-demo"
                        fullWidth
                        size='small'
                        options={optionsList}
                        onChange={(e, value) => setHotelId(value?.id ?? "")}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderInput={(params) => <TextField {...params} label="Filter by hotel" />}
                        sx={{ width: 250 }}
                    />
                    <TextField
                        fullWidth
                        size="small"
                        type="date"
                        label="Filter by booked date"
                        placeholder="Filter by booked date"
                        sx={{ width: 200 }}
                        value={bookedAt ? moment.unix(bookedAt).format("YYYY-MM-DD") : ""}
                        onChange={(newValue) => {
                            setBookedAt(moment(newValue.target.value, "YYYY-MM-DD").startOf("day").unix())
                        }}
                        inputRef={bookedAtInputRef}
                        onClick={() => bookedAtInputRef.current.showPicker()}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <CalendarMonth sx={{ cursor: "pointer" }} />
                                </InputAdornment>
                            ),
                        }}
                    />
                    {/* <TextField
                        select
                        size="small"
                        variant="outlined"
                        label="Hotel"
                        value={hotelId || "all"}
                        sx={{ width: 200 }}
                        onChange={(e) => setHotelId(e.target.value)}
                    >
                        <MenuItem value={"all"}>
                            {"All"}
                        </MenuItem>
                        {hotels?.list?.map((e) => (
                            <MenuItem value={e._id} key={e._id}>
                                {e.name}
                            </MenuItem>
                        ))}
                    </TextField> */}
                    <CsvDownloader filename="Transactions" datas={generateCsvDownloadData(bookingsList)}>
                        <IconButton title="Export as CSV" sx={{ borderRadius: "5px", bgcolor: colors.greenAccent[700] }}>
                            <Download />
                        </IconButton>
                    </CsvDownloader>
                </Stack>
            </Box>
            <Box
                m="8px 0 0 0"
                height="80vh"
                sx={tableStyle(colors)}
            >
                <DataGrid 
                    rows={bookingsList} 
                    columns={columns} 
                    getRowId={(row) => row?._id} 
                    components={{ Footer: CustomFooter }}
                />
            </Box>
        </Box>
    );
};

export default Transactions;
