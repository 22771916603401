import Dashboard from "./views/dashboard";
import Hotels from "./views/hotels";
import HotelDetails from "./views/hotels/hotelDetails";
import Invoices from "./views/invoices";
import Packages from "./views/packages";
import InvoiceCalendar from "./views/invoice-calendar";
import CreateInvoice from "./views/invoice-calendar/createInvoice";
import InvoiceDetails from "./views/invoices/invoiceDetails";
import Transactions from "./views/transactions";
import Bookings from "./views/bookings";

export const adminRoutes = [
    {
        path: "/dashboard",
        component: <Dashboard />,
    },
    {
        path: "/hotels",
        component: <Hotels />,
    },
]

if(localStorage.getItem("accountType") === "admin"){
    adminRoutes.push(
        {
            path: "/hotel-details/:id",
            component: <HotelDetails />,
        },
        {
            path: "/packages",
            component: <Packages />,
        },
        {
            path: "/invoices",
            component: <Invoices />,
        },
        {
            path: "/transactions",
            component: <Transactions />,
        },
        {
            path: "/bookings",
            component: <Bookings />,
        },
        {
            path: "/invoice-details/:id",
            component: <InvoiceDetails />,
        },
        {
            path: "/invoice-calendar",
            component: <InvoiceCalendar />,
        },
        {
            path: "/create-invoice",
            component: <CreateInvoice />,
        },
    )
}