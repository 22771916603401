export const ADD_NEW_HIGHLIGHT = `mutation ($name: String!, $icon: String!) {
    add_new_highlight(name: $name, icon: $icon){
      message
    }
  }
`;

export const ACTIVATE_HIGHLIGHT = `mutation ($id : String!) {
    activate_highlight(_id: $id){
      message
    }
  }
`;

export const LIST_ALL_HIGHLIGHTS = `query ($pageSize: Int, $pageNumber: Int) {
    list_all_highlights(page_size: $pageSize, page_number: $pageNumber){
      message
      data {
        _id
        name
        icon
        is_active
      }
      hasMore
    }
  }
`;

  export const ADD_NEW_BOOKING = `mutation ($name: String!, $email: String!, $checkinDate: Int, $checkoutDate: Int, $totalPrice: Float, $rooms:[String]) {
    add_new_booking(name: $name, email: $email, checkin_date: $checkinDate, checkout_date: $checkoutDate, total_price: $totalPrice, rooms: $rooms){
      message
    }
  }
`;

export const ADD_NEW_HOTEL = `mutation ($name: String!, $email: String!, $address: String!, $mobile: String, $type: String) {
  add_new_hotel(name: $name, email: $email, address: $address, mobile: $mobile, type: $type){
    message
  }
}
`;

export const LIST_ALL_HOTELS = `query ($pageSize: Int, $pageNumber: Int) {
  list_all_hotels(page_size: $pageSize, page_number: $pageNumber){
    message
    data {
      _id
      name
      email
      address
      status
      is_active
    }
    hasMore
  }
}
`;

export const LIST_HOTEL_DETAILS = `query ($id: String) {
  list_hotel_details(_id: $id){
    message
    data {
      _id
      name
      email
      address
      status
      mobile
      type
      is_active
      is_dependent
      is_connected
      revenue_manager
      gstin
      bank_details {
        acc_holder_name
        acc_no
        ifsc
        acc_type
        bank_name
        branch_name
      }
    }
  }
}`;

export const ADD_NEW_ROOM = `mutation ($roomNo: String, $numOfBeds: Int, $minNumOfPeople: Int, $maxNumOfPeople: Int, $highlights: [String], $facilities: [String], $images: [String]) {
    add_new_room(room_no: $roomNo, no_of_beds: $numOfBeds, min_no_of_people: $minNumOfPeople, max_no_of_people: $maxNumOfPeople, highlights: $highlights, facilities: $facilities, images: $images){
      message
    }
  }
`;

export const LIST_ALL_ROOMS = `query ($pageSize: Int, $pageNumber: Int) {
    list_all_rooms(page_size: $pageSize, page_number: $pageNumber){
      message
      data {
        _id
        room_no
        no_of_beds
        min_no_of_people
        max_no_of_people
        is_active
      }
      hasMore
    }
  }
`;

export const ADD_NEW_FACILITY = `mutation ($name: String!, $icon: String!) {
    add_new_facility(name: $name, icon: $icon){
      message
    }
  }
`;

export const LIST_ALL_FACILITIES = `query ($pageSize: Int, $pageNumber: Int) {
    list_all_facilities(page_size: $pageSize, page_number: $pageNumber){
      message
      data {
        _id
        name
        icon
        is_active
      }
      hasMore
    }
  }
`;

export const LIST_ALL_HOTEL_LOGS = `query ($pageSize: Int, $pageNumber: Int, $hotelId: String!) {
  list_all_hotel_logs(page_size: $pageSize, page_number: $pageNumber, hotel_id: $hotelId){
    message
    data {
      _id
      ip_address
      device
      action
      remarks
      timestamp
    }
    hasMore
    count
  }
}`;

export const LIST_ALL_BOOKINGS = `query ($hotelId: String, $pageSize: Int, $pageNumber: Int, $startDate: Int, $endDate: Int, $bookedAt: Int, $bookingFrom: String) {
  list_all_bookings(hotel_id: $hotelId, page_size: $pageSize, page_number: $pageNumber, start_date: $startDate, end_date: $endDate, booked_at: $bookedAt, booking_from: $bookingFrom){
    message
    data {
      _id
      booking_id
      checkin_date
      checkout_date
      status
      total_price
      custom_price
      total_rooms
      paid_amount
      unpaid_amount
      booking_from
      guest_name
      guest_mobile
      guest_email
      createdAt
      hotel_id{
        _id
        name
      }
      order {
        order_id
        transaction_id
        amount
        status
        updatedAt
      }
    } 
    hasMore
    count
  }
}`;

export const LIST_ALL_INVOICES = `query ($hotelId: String, $pageSize: Int, $pageNumber: Int, $status: String, $startDate: Int, $endDate: Int) {
  list_all_invoices(hotel_id: $hotelId, page_size: $pageSize, page_number: $pageNumber, status: $status, start_date: $startDate, end_date: $endDate){
    message
    data {
      _id
      inv_date
      inv_no
      to
      tax
      total_amount
      bal_amount
      paid_amount
      custom_amount
      pmt_status
      email_status
      remarks
      is_active
      hotel_id {
        _id
        name
      }
    }
    hasMore
    count
  }
}`;

export const UPDATE_INVOICE = `mutation ($invNo: String!, $pmtStatus: String!, $partialAmount: String, $paymentDate: Int) {
  update_invoice(inv_no: $invNo, pmt_status: $pmtStatus, partial_amount: $partialAmount, payment_date: $paymentDate){
    message
  }
}`;

export const LIST_ALL_CALENDAR_INVOICES = `query ($year: String) {
  list_all_calendar_invoices(year: $year){
    message
    data {
      _id
      name
      package
      rate_per_night
      data {
        month
        year
        invoice {
          _id
          total_amount
          remarks
          invoice_id {
            _id
            inv_no
            total_amount
            custom_amount
            pmt_status
            remarks
          }
        }
      }
    }
  }
}`;

export const GENERATE_INVOICE_DETAILS = `query ($hotelId: String!, $months: [generate_invoice_details_months]!) {
  generate_invoice_details(hotel_id: $hotelId, months: $months){
    message
    data {
      month
      total_amount
      remarks
    }
  }
}`;

export const CREATE_INVOICES = `mutation ($hotelId: String!, $packageId:String, $months: [create_invoices_months]!, $customAmount: Float, $invDate: Int, $invType: String) {
  create_invoices(hotel_id: $hotelId, package_id: $packageId, months: $months, custom_amount: $customAmount, inv_date: $invDate, inv_type: $invType){
    message
  }
}`;

export const UPDATE_HOTEL_DEPENDENCY = `mutation ($id: String!) {
  update_hotel_dependency(_id: $id){
    message
  }
}`;

export const UPDATE_HOTEL_CONNECTED = `mutation ($id: String!) {
  update_hotel_connected(_id: $id){
    message
  }
}`;

export const UPDATE_HOTEL_REVENUE_MANAGER = `mutation ($id: String!) {
  update_hotel_revenue_manager(_id: $id){
    message
  }
}`;

export const ACTIVATE_HOTEL = `mutation ($id: String!) {
  activate_hotel(_id: $id){
    message
  }
}`;

export const LIST_INVOICE_DETAILS = `query ($invoiceNo: String) {
  list_invoice_details(invoice_no: $invoiceNo){
    message
    data {
      _id
      inv_date
      inv_no
      to
      tax
      total_amount
      bal_amount
      paid_amount
      custom_amount
      pmt_status
      email_status
      remarks
      paid_date
      partially_paid_date
      is_active
      hotel_id {
        _id
        name
      }
      months {
        _id
        remarks
        start_date
        end_date
      }
      createdAt
      package
    }
  }
}`;

export const CANCEL_INVOICE = `mutation ($invNo: String!) {
  cancel_invoice(inv_no: $invNo){
    message
  }
}`;
