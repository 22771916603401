/*eslint-disable*/
import React, { useEffect } from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { listAllHotelLogs, resetAllHotelLogs } from "../../../store/actions";
import { useSelector } from "react-redux";
import { tableStyle } from "../../mui-styles/table";
import { DataGrid } from "@mui/x-data-grid";
import { PaginationFooter } from "../../../components";
import moment from "moment";

const HotelLogs = ({hotelId, dispatch, colors}) => {
    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.up("sm"));

    useEffect(() => {
        dispatch(resetAllHotelLogs());
        fetchMoreData();
    }, [dispatch])

    const { hotelLogsList, pageNumber, pageSize, count } = useSelector(
        ({
            admin: {
                hotelLogs: { list, pageNumber, pageSize, count },
            },
        }) => ({
            hotelLogsList: list,
            pageNumber, pageSize, count,
        })
    );

    const fetchMoreData = (page) => {
        dispatch(listAllHotelLogs({ hotelId: hotelId, ...page }));
    }
    const CustomFooter = () => {
        return (
            <PaginationFooter
                count={count}
                pageSize={pageSize}
                pageNumber={pageNumber}
                fetchMoreData={(page) => fetchMoreData(page)}
            />
        );
    }

    const columns = [
        { 
            field: "timestamp", 
            headerName: "Date and Time", 
            width: 180,
            renderCell: ({ row: { timestamp } }) => `${timestamp ? moment.unix(timestamp).format("DD-MMM-YYYY - h:mm a") : "-"}`, 
        },
        { field: "device", headerName: "Device", cellClassName: "name-column--cell", width: 200 },
        { field: "ip_address", headerName: "IP Address", width: 150 },
        { field: "action", headerName: "Action", width: 150 },
        { field: "remarks", headerName: "Remarks", width: 500 },
    ];

    return (
        <Box>
            <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                m="20px 0"
            >
                <Typography fontWeight="bold" variant={smScreen ? "h3" : "h4"}>
                    HOTEL LOGS
                </Typography>
            </Box>
            <Box
                m="8px 0 0 0"
                height="80vh"
                sx={tableStyle(colors)}
            >
                <DataGrid 
                    rows={hotelLogsList} 
                    columns={columns} 
                    getRowId={(row) => row?._id} 
                    components={{ Footer: CustomFooter }}
                />
            </Box>
        </Box>
    );
};

export default HotelLogs;
