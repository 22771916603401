import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, CircularProgress, Divider, Grid, Stack, Typography, useTheme } from '@mui/material';
import { Close, Done } from '@mui/icons-material';
import { CssTextField } from '../../components/TextField';
import { useNavigate } from 'react-router-dom';
import { tokens } from '../../theme';
import Header from '../../components/Header';
import { authorizedPost, toggleLoading } from '../../store/actions';
import { CREATE_INVOICES, GENERATE_INVOICE_DETAILS } from '../../graphql';
import moment from 'moment';

function CreateInvoice() {
    const navigate = useNavigate();
    const theme = useTheme();
    const dispatch = useDispatch();
    const colors = tokens(theme.palette.mode);

    const selectedHotelId = JSON.parse(localStorage.getItem("selectedHotelId"));
    const selectedMonths = JSON.parse(localStorage.getItem("selectedMonths"));
    const [invoiceDetails, setInvoiceDetails] = useState([]);
    const [totalAmt, setTotalAmt] = useState(0);
    const [customAmt, setCustomAmt] = useState("");
    const [invDate, setInvDate] = useState(moment().format("YYYY-MM-DD"));
    // eslint-disable-next-line
    const [temp, setTemp] = useState("");
    const { isLoading } = useSelector(({ loading }) => ({ isLoading: loading }));

    useEffect(() => {
        if (!selectedHotelId || !selectedMonths) {
            navigate("/invoice-calendar");
        }
        fetchData();
        // eslint-disable-next-line
    }, [])

    const fetchData = async () => {
        dispatch(toggleLoading(true));
        const result = await authorizedPost(GENERATE_INVOICE_DETAILS, {
            hotelId: selectedHotelId,
            months: selectedMonths?.map((month) => ({
                month: month
            })),
        })
        if (result?.status) {
            const { generate_invoice_details: { data } } = result?.data;
            let updated = data?.map((e) => ({
                ...e,
                custom_amount: 0,
            }))
            setTotalAmt(updated?.reduce((k,l) => k+l.total_amount, 0))
            setInvoiceDetails(updated);
        }
        else {
            navigate("/invoice-calendar")
        }
        dispatch(toggleLoading(false));
    }

    // const handleChange = (event, i) => {
    //     let valueAdd = invoiceDetails;
    //     let keyName = event?.target?.name;
    //     if (event?.target) {
    //         valueAdd[i][keyName] = event.target.value;
    //     } else {
    //         valueAdd[i][keyName] = event
    //     }
    //     setTemp(event.target.value);
    //     setInvoiceDetails(valueAdd);
    // }

    const handleSubmit = async (e) => {
        e.preventDefault();
        dispatch(toggleLoading(true));
        let modifled = invoiceDetails?.map((prop) => ({
            month: prop?.month,
            custom_amount: prop?.custom_amount,
        }))
        await authorizedPost(CREATE_INVOICES, {
            hotelId: selectedHotelId,
            months: modifled,
            customAmount: customAmt || 0,
            invDate: moment(invDate, "YYYY-MM-DD").startOf("day").unix(),
            invType: "calendar"
        })
        dispatch(toggleLoading(false));
        navigate("/invoice-calendar")
    }

    return (
        <>
        {invoiceDetails.length ? (
            <Box m="20px" mb={15}>
                <Header title="CREATE INVOICE" />
                <Box
                    width="100%"
                    p={2}
                    borderRadius={2}
                    backgroundColor={colors.primary[400]}
                >
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            {invoiceDetails?.map((element, index) => {
                                return (
                                    <Grid item md={6} xs={12} key={index}>
                                        <Box
                                            width="100%"
                                            p={2}
                                            borderRadius={2}
                                            backgroundColor={colors.primary[500]}
                                        >
                                            <Stack spacing={2}>
                                                <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="start">
                                                    <Typography width={"70%"}>
                                                        Month:
                                                    </Typography>
                                                    <Typography>
                                                        {element?.month}
                                                    </Typography>
                                                </Stack>
                                                <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="start">
                                                    <Typography width={"70%"}>
                                                        Remarks:
                                                    </Typography>
                                                    <Typography textAlign="end">
                                                        {element?.remarks}
                                                    </Typography>
                                                </Stack>
                                                <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="start">
                                                    <Typography width={"70%"}>
                                                        Amount:
                                                    </Typography>
                                                    <Typography>
                                                        {element.total_amount}
                                                    </Typography>
                                                </Stack>
                                                {/* <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="start">
                                                    <Typography width={"70%"}>
                                                        Custom Amount:
                                                    </Typography>
                                                    <CssTextField
                                                        fullWidth
                                                        required
                                                        size='small'
                                                        type="number"
                                                        placeholder="Custom amount"
                                                        name="custom_amount"
                                                        value={element?.custom_amount}
                                                        onChange={(e) => handleChange(e, index)}
                                                        InputProps={{ inputProps: { min: 0 } }}
                                                    />
                                                </Stack> */}
                                            </Stack>
                                        </Box>
                                    </Grid>
                                )
                            })}
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Stack mb={2} direction="row" spacing={2} justifyContent="space-between" alignItems="start">
                                    <Typography width={"70%"}>
                                        Total Amount:
                                    </Typography>
                                    <Typography>
                                        {totalAmt ?? "-"}
                                    </Typography>
                                </Stack>
                                <Stack mb={2} direction="row" spacing={2} justifyContent="space-between" alignItems="start">
                                    <Typography width={"70%"}>
                                        GST Amount:
                                    </Typography>
                                    <Typography>
                                        {customAmt ? customAmt * (18 / 100) : totalAmt ? totalAmt * (18 / 100) : "-"}
                                    </Typography>
                                </Stack>
                                <Stack mb={2} direction="row" spacing={2} justifyContent="space-between" alignItems="center">
                                    <Typography width={"70%"}>
                                        Custom Amount:
                                    </Typography>
                                    <CssTextField
                                        fullWidth
                                        // required
                                        size='small'
                                        type="number"
                                        placeholder="Custom amount"
                                        value={customAmt}
                                        onChange={(e) => setCustomAmt(e.target.value)}
                                        InputProps={{ inputProps: { min: 0 } }}
                                    />
                                </Stack>
                                <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
                                    <Typography width={"70%"}>
                                        Invoice Date:
                                    </Typography>
                                    <CssTextField
                                        fullWidth
                                        required
                                        size='small'
                                        type="date"
                                        placeholder="Invoice Date"
                                        value={invDate}
                                        onChange={(e) => setInvDate(e.target.value)}
                                    />
                                </Stack>
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <Stack mt={2} direction="row" justifyContent="space-between">
                                    <Button
                                        variant="outlined"
                                        onClick={() => navigate(-1)}
                                        startIcon={<Close />}
                                    >
                                        <b>Cancel</b>
                                    </Button>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        endIcon={<Done />}
                                    >
                                        {isLoading ? <CircularProgress color="inherit" size={24} /> : <b>Submit</b>}
                                    </Button>
                                </Stack>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Box>
        ) : null}
        </>
    )
}

export default CreateInvoice;