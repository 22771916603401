// /* eslint-disable */
import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Stack, Tab, Tabs, Typography, useTheme } from "@mui/material";
import { activateHotel, authorizedPost, toggleLoading, updateHotelConnectedRooms, updateHotelDependency, updateHotelRevenueManager } from "../../store/actions";
import { useNavigate, useParams } from "react-router-dom";
import { LIST_HOTEL_DETAILS } from "../../graphql";
import { useDispatch } from "react-redux";
import { ArrowBack } from "@mui/icons-material";
import { tokens } from "../../theme";
import HotelPackages from "./hotel-packages";
import HotelLogs from "./hotel-logs";
import Bookings from "./bookings";
import SwitchWithConfirmModal from "../../components/SwitchWithConfirmModal";

function HotelDetails() {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [hotelDetails, setHotelDetails] = useState({});
    const [tab, setTab] = useState(1);

    useEffect(() => {
        document.title = "Hotel details";
        fetchHotelDetails();
        // eslint-disable-next-line
    }, [dispatch, id]);
    // console.log(hotelDetails);

    const fetchHotelDetails = async () => {
        dispatch(toggleLoading(true));
        let response = await authorizedPost(LIST_HOTEL_DETAILS, { id: id });
        if (response?.data) {
            setHotelDetails(response.data.list_hotel_details.data);
        }
        dispatch(toggleLoading(false));
    };

    const FieldAndValue = ({ field, value }) => (
        <Grid item xs={12} md={6}>
            <Stack direction="row" alignItems="center" spacing={2}>
                <Typography width={"30%"}>{field}:</Typography>
                <Typography >{value || "-"}</Typography>
            </Stack>
        </Grid>
    )

    return (
        <Box m="20px" mb={15}>
            <Stack direction={"row"} alignItems={"end"} spacing={1} m="20px 0">
                <Button size="small" variant="outlined" onClick={() => navigate(-1)}>
                    <ArrowBack fontSize="small" />
                </Button>
                <Typography fontWeight="bold" variant="h4" textTransform={"uppercase"} color={colors.greenAccent[500]}>
                    {hotelDetails?.name}
                </Typography>
            </Stack>

            <Box sx={{ borderBottom: 1, mb: 2, borderColor: "divider" }}>
                <Tabs
                    value={tab}
                    onChange={(e, n) => setTab(n)}
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    <Tab value={1} label="Details" sx={{ fontWeight: "bold" }} />
                    <Tab value={2} label="Bookings" sx={{ fontWeight: "bold" }} />
                    <Tab value={3} label="Hotel Packages" sx={{ fontWeight: "bold" }} />
                    <Tab value={4} label="Hotel Logs" sx={{ fontWeight: "bold" }} />
                </Tabs>
            </Box>

            {tab === 1 && (
                <Box
                    width="100%"
                    p={2}
                    borderRadius={2}
                    backgroundColor={colors.primary[400]}
                >
                    <Grid container spacing={2}>
                        <FieldAndValue field={"Hotel name"} value={hotelDetails?.name} />
                        <FieldAndValue field={"Email"} value={hotelDetails?.email} />
                        <FieldAndValue field={"Mobile"} value={hotelDetails?.mobile} />
                        <FieldAndValue field={"Address"} value={hotelDetails?.address} />
                        <FieldAndValue field={"Type"} value={hotelDetails?.type} />
                        <FieldAndValue field={"Status"} value={hotelDetails?.status} />
                        {/* <Grid item xs={12} md={12}>
                            <Typography mt={2} variant='h4' color={colors.greenAccent[500]} fontWeight={700}>
                                Bank account details
                            </Typography>
                        </Grid>
                        <FieldAndValue field={"Account holder name"} value={hotelDetails?.bank_details?.acc_holder_name} />
                        <FieldAndValue field={"Account number"} value={hotelDetails?.bank_details?.acc_no} />
                        <FieldAndValue field={"IFSC code"} value={hotelDetails?.bank_details?.ifsc} /> */}
                        <Grid item xs={12} md={12}>
                            <Box sx={{ borderBottom: 1, borderColor: "divider" }}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography width={"30%"}>{"Dependency"}:</Typography>
                                <SwitchWithConfirmModal
                                    isActive={hotelDetails?.is_dependent || false}
                                    action={async() => {
                                        await dispatch(updateHotelDependency({ id: id }))
                                        fetchHotelDetails();
                                    }}
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography width={"30%"}>{"Connected rooms"}:</Typography>
                                <SwitchWithConfirmModal
                                    isActive={hotelDetails?.is_connected || false}
                                    action={async() => {
                                        await dispatch(updateHotelConnectedRooms({ id: id }))
                                        fetchHotelDetails();
                                    }}
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography width={"30%"}>{"Revenue manager"}:</Typography>
                                <SwitchWithConfirmModal
                                    isActive={hotelDetails?.revenue_manager || false}
                                    action={async() => {
                                        await dispatch(updateHotelRevenueManager({ id: id }))
                                        fetchHotelDetails();
                                    }}
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography width={"30%"}>{"Activate / deactivate hotel"}:</Typography>
                                <SwitchWithConfirmModal
                                    isActive={hotelDetails?.is_active || false}
                                    action={async() => {
                                        await dispatch(activateHotel({ id: id }))
                                        fetchHotelDetails();
                                    }}
                                />
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>
            )}

            {tab === 2 && (
                <Bookings
                    hotelId={id}
                    dispatch={dispatch}
                    colors={colors}
                />
            )}
            {tab === 3 && (
                <HotelPackages
                    hotelId={id}
                    dispatch={dispatch}
                    colors={colors}
                />
            )}
            {tab === 4 && (
                <HotelLogs
                    hotelId={id}
                    dispatch={dispatch}
                    colors={colors}
                />
            )}
            {/* {tab === 4 && (
                <RoomtypeOtaCount
                    roomtypeId={id}
                    dispatch={dispatch}
                    colors={colors}
                />
            )} */}
        </Box>
    );
}

export default HotelDetails;
