/* eslint-disable */
import { Box, useTheme, useMediaQuery } from "@mui/material";
import { tokens } from "../../theme";
import { Apartment, CalendarMonth, Dataset, Receipt } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Header from "../../components/Header";
import StatBox from "../../components/StatBox";

const Dashboard = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    document.title = 'Dashboard';
    localStorage.removeItem("year");
  }, []);

  return (
    <Box m="20px">
      {/* HEADER */}

      <Box
        display={smScreen ? "flex" : "block"}
        flexDirection={smScreen ? "row" : "column"}
        justifyContent={smScreen ? "space-between" : "start"}
        alignItems={smScreen ? "center" : "start"}
        m="10px 0"
      >
        <Header title="DASHBOARD" subtitle="Welcome to your dashboard" />

        {/* <Box>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            <DownloadOutlinedIcon sx={{ mr: "10px" }} />
            Download Reports
          </Button>
        </Box> */}
      </Box>

      {/* GRIDS */}
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 2 }}>
        <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
          <Box
            width="100%"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{cursor: "pointer"}}
            onClick={() => navigate("/hotels")}
          >
            <StatBox
              title="Hotels"
              subtitle="Click to explore Hotels"
              // progress="0.75"
              // increase="+14%"
              icon={
                <Apartment
                  sx={{ color: colors.greenAccent[500], fontSize: "26px" }}
                />
              }
            />
          </Box>
        </Grid>
        {localStorage.getItem("accountType") === "admin" ? (
          <>
            <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
              <Box
                width="100%"
                backgroundColor={colors.primary[400]}
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{ cursor: "pointer" }}
                onClick={() => navigate("/packages")}
              >
                <StatBox
                  title="Packages"
                  subtitle="Click to explore Packages"
                  // progress="0.50"
                  // increase="+21%"
                  icon={
                    <Dataset
                      sx={{ color: colors.greenAccent[500], fontSize: "26px" }}
                    />
                  }
                />
              </Box>
            </Grid>
            <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
              <Box
                width="100%"
                backgroundColor={colors.primary[400]}
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{ cursor: "pointer" }}
                onClick={() => navigate("/invoices")}
              >
                <StatBox
                  title="Invoices"
                  subtitle="Click to explore Invoices"
                  icon={
                    <Receipt
                      sx={{ color: colors.greenAccent[500], fontSize: "26px" }}
                    />
                  }
                />
              </Box>
            </Grid>
            <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
              <Box
                width="100%"
                backgroundColor={colors.primary[400]}
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{ cursor: "pointer" }}
                onClick={() => navigate("/invoice-calendar")}
              >
                <StatBox
                  title="Invoice calendar"
                  subtitle="Click to explore Invoice calendar"
                  icon={
                    <CalendarMonth
                      sx={{ color: colors.greenAccent[500], fontSize: "26px" }}
                    />
                  }
                />
              </Box>
            </Grid>
          </>
        ) : null}
      </Grid>
    </Box>
  );
};

export default Dashboard;
