import React from "react";
import { useSelector } from "react-redux";
import { Box, Button, CircularProgress, TextField } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { Formik } from "formik";
import Header from "../../components/Header";
import * as yup from "yup";

function LoginWithOtp({ onSubmit }) {
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const { isLoading } = useSelector(({ loading }) => ({
    isLoading: loading,
  }));
  const handleFormSubmit = (values) => {
    onSubmit(values);
  };
  const initialValues = {
    otp: "",
  };
  const checkoutSchema = yup.object().shape({
    otp: yup.string().required("Required"),
  });

  return (
    <>
      <Box m="20px">
        <Header title="ADMIN LOGIN" subtitle="Enter the OTP sent to your email" />
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={checkoutSchema}
        >
          {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                }}
              >
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="OTP"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.otp}
                  name="otp"
                  error={!!touched.otp && !!errors.otp}
                  helperText={touched.otp && errors.otp}
                  sx={{ gridColumn: "span 4" }}
                />
              </Box>
              <Box mt="20px">
                <Button
                  fullWidth
                  size="large"
                  type="submit"
                  color="secondary"
                  variant="contained"
                  sx={{ fontSize: "15px" }}
                >
                  {isLoading ? <CircularProgress color="inherit" size={24} /> : <b>SUBMIT</b>}
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </>
  );
}

export default LoginWithOtp;
