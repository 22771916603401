export const LOGIN_WITH_OTP = `query ($email: String!, $otp: String!) {
    admin_login(email: $email, otp: $otp) {
      message
      token
    }
  }
  `;

export const SEND_OTP = `mutation ($email: String!) {
    send_otp(email: $email) {
      message
    }
  }
  `;

export const GENERATE_HOTEL_TOKEN = `query ($hotelId: String!) {
  generate_hotel_token(hotel_id: $hotelId){
    message
    token
  }
}`;