import React, { useEffect, useRef, useState } from "react";
import { 
    Box, Button, Divider, Grid, ListItemIcon, ListItemText, 
    Menu, MenuItem, Modal, Stack, Typography, useTheme 
} from '@mui/material'
import { ArrowBack, Build, Cancel, Download, KeyboardArrowDown } from '@mui/icons-material';
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { PaymentStatusChip, StatusChip } from "../../components/Chips";
import { tokens } from "../../theme";
import moment from "moment";
import { authorizedPost, toggleLoading } from "../../store/actions";
import { LIST_HOTEL_DETAILS, LIST_INVOICE_DETAILS } from "../../graphql";
import { useReactToPrint } from "react-to-print";
import UpdatePaymentStatus from "./updatePaymentStatus";
import InvoiceTemplate from "./InvoiceTemplate";
import CancelInvoiceModal from "./cancelInvoice";

const modalStyle = {
    height: "100%",
    width: "100%",
    overflow: "auto",
    outline: "none",
    boxShadow: 24,
    p: 2,
};

function InvoiceDetails() {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [invDetails, setInvDetails] = useState({});
    const [hotelDetails, setHotelDetails] = useState({});
    const [updateStatusModal, setUpdateStatusModal] = useState(false);
    const [cancelInvModal, setCancelInvModal] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(anchorEl);
    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        document.title = "Invoice details";
        fetchData();
    // eslint-disable-next-line
    }, [dispatch, id]);

    const fetchData = async () => {
        dispatch(toggleLoading(true));
        const result = await authorizedPost(LIST_INVOICE_DETAILS, { invoiceNo: id })
        if (result?.status) {
            const { list_invoice_details: { data } } = result?.data;
            setInvDetails(data);

            let response = await authorizedPost(LIST_HOTEL_DETAILS, { id: data?.hotel_id?._id });
            if (response?.data) {
                setHotelDetails(response.data.list_hotel_details.data);
            }
        }
        else {
            navigate("/invoices")
            return;
        }
        dispatch(toggleLoading(false));
    }

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });

    const KeyAndValue = ({ title, value, render }) => (
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
            <Typography width={"40%"}>{title} :</Typography>
            {render ? render : <Typography textAlign="end">{value}</Typography>}
        </Stack>
    )

    return (
        <Box m="20px" mb={15}>
            <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"flex-end"}
                spacing={2}
                m="20px 0"
            >
                <Button size="small" variant="outlined" onClick={() => navigate(-1)}>
                    <ArrowBack fontSize="small" />
                </Button>
                <Typography fontWeight="bold" variant="h4">
                    INVOICE DETAILS
                </Typography>
                <Button
                    size="small"
                    variant="outlined"
                    endIcon={<KeyboardArrowDown />}
                    onClick={handleMenuClick}
                    disabled={localStorage.getItem("accountType") !== "admin"}
                >
                    <b>Update</b>
                </Button>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={openMenu}
                    onClose={handleMenuClose}
                    MenuListProps={{
                        "aria-labelledby": "basic-button",
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <MenuItem
                        onClick={() => {
                            handleMenuClose()
                            setUpdateStatusModal(true)
                        }}
                    >
                        <ListItemIcon>
                            <Build fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Update Payment Status</ListItemText>
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            handleMenuClose()
                            setCancelInvModal(true)
                        }}
                        disabled={!invDetails?.is_active}
                    >
                        <ListItemIcon>
                            <Cancel fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Cancel invoice</ListItemText>
                    </MenuItem>
                </Menu>
            </Stack>
            <Box width="100%" p={2} borderRadius={2} backgroundColor={colors.primary[400]}>
                <Grid container rowSpacing={2} columnSpacing={{ md: 6, xs: 2 }}>
                    <Grid item md={12} xs={12}>
                        <Stack alignItems="end">
                            <Button 
                                size="small" 
                                variant="contained"
                                endIcon={<Download />}
                                onClick={handlePrint}
                                disabled={localStorage.getItem("accountType") !== "admin"}
                            >
                                <b>Download Invoice</b>
                            </Button>
                        </Stack>
                        <Box display="none">
                            <InvoiceTemplate data={invDetails} hotel={hotelDetails} refer={componentRef}/>
                        </Box>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Stack spacing={2}>
                            <KeyAndValue title={"Invoice no"} value={<small>{invDetails?.inv_no || "-"}</small>}/>
                            <KeyAndValue title={"Invoice date"} value={moment.unix(invDetails?.inv_date).format("DD-MMM-YYYY") || "-"}/>
                            <KeyAndValue title={"Bill to"} value={invDetails?.to ?? "-"}/>
                            <KeyAndValue title={"Tax"} value={invDetails?.tax ?? "-"}/>
                            <KeyAndValue title={"Hotel"} value={invDetails?.hotel_id?.name ?? "-"}/>
                            <KeyAndValue title={"Invoice status"} render={<StatusChip status={invDetails?.is_active ? "active" : "cancelled"} />}/>
                        </Stack>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Stack spacing={2}>
                            {invDetails?.custom_amount ? (
                                <KeyAndValue title={"Custom amount"} value={`₹${invDetails?.custom_amount ?? "0"}`}/>
                            ) : (
                                <KeyAndValue title={"Total amount"} value={`₹${invDetails?.total_amount ?? "0"}`}/>
                            )}
                            <KeyAndValue title={"GST amount"} value={`₹${invDetails?.custom_amount ? invDetails?.custom_amount * (18 / 100) : invDetails?.total_amount ? invDetails?.total_amount * (18 / 100) : "-"}`}/>
                            <KeyAndValue title={"Paid amount"} value={`${invDetails?.paid_amount ?? "-"}`}/>
                            <KeyAndValue title={"Balance amount"} value={`${invDetails?.bal_amount ?? "-"}`}/>
                            {invDetails?.paid_date ?
                            <KeyAndValue title={"Fully paid date"} value={invDetails?.paid_date ? moment.unix(invDetails?.paid_date).format("DD-MMM-YYYY") : "-"}/>
                            : null}
                            {invDetails?.partially_paid_date ?
                            <KeyAndValue title={"Partially paid date"} value={invDetails?.partially_paid_date ? moment.unix(invDetails?.partially_paid_date).format("DD-MMM-YYYY") : "-"}/>
                            : null}
                            <KeyAndValue title={"Email status"} value={invDetails?.email_status ?? "-"}/>
                            <KeyAndValue title={"Payment status"} render={<PaymentStatusChip status={invDetails?.pmt_status} />}/>
                        </Stack>
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <Divider />
                    </Grid>
                    {invDetails?.months?.map((el, ind) => (
                        <Grid item md={6} xs={12} key={ind}>
                            <Stack
                                p={2}
                                borderRadius={2}
                                border={1}
                                borderColor={colors.greenAccent[600]}
                                bgcolor={colors.primary[500]}
                                spacing={2}
                            >
                                <Typography color={colors.greenAccent[600]} fontWeight={"bold"}>
                                    {moment.unix(el?.start_date).format("MMM-YYYY")}
                                </Typography>
                                <KeyAndValue title={"Start date"} value={el?.start_date ? moment.unix(el?.start_date).format("DD-MMM-YYYY") : "-"}/>
                                <KeyAndValue title={"End date"} value={el?.end_date ? moment.unix(el?.end_date).format("DD-MMM-YYYY") : "-"}/>
                                <KeyAndValue title={"Remarks"} value={el?.remarks ?? "-"}/>
                            </Stack>
                        </Grid>
                    ))}
                </Grid>
            </Box>

            <Modal open={updateStatusModal} onClose={() => setUpdateStatusModal(false)} sx={modalStyle}>
                <>
                    <UpdatePaymentStatus
                        invoiceDetails={invDetails}
                        setUpdateStatusModal={setUpdateStatusModal}
                        fetchData={fetchData}
                    />
                </>
            </Modal>
            <Modal open={cancelInvModal} onClose={() => setCancelInvModal(false)} sx={modalStyle}>
                <>
                    <CancelInvoiceModal
                        invoiceNo={invDetails?.inv_no}
                        setCancelInvModal={setCancelInvModal}
                        fetchData={fetchData}
                    />
                </>
            </Modal>
        </Box>
    );
}

export default InvoiceDetails;
