import { Chip } from "@mui/material";

export const StatusChip = ({ status }) => {
    return (
        <Chip
            size="small"
            variant="outlined"
            label={status === "hold" ? "blocked" : status || "-"}
            sx={{textTransform: "lowercase"}}
            color={
                ["confirmed", "booked", "Success", "active"].includes(status) ? "success" : 
                ["cancelled", "failed"].includes(status) ? "error" : 
                status === "checkin" ? "info" : 
                "warning"
            }
        />
    )
}

export const PaymentStatusChip = ({ status }) => {
    return (
        <Chip
            size="small"
            variant="outlined"
            label={status === "partial_pmt" ? "partial" : status || "-"}
            color={
                status === "paid" ? "success" : 
                status === "unpaid" ? "error" : 
                status === "partial_pmt" ? "warning" : 
                "default"
            }
        />
    )
}