/*eslint-disable*/
import React, { useEffect } from "react";
import { Box, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { listAllBookings, resetAllBookings } from "../../../store/actions";
import { useSelector } from "react-redux";
import { tableStyle } from "../../mui-styles/table";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import { StatusChip } from "../../../components/Chips";

const Bookings = ({hotelId, dispatch, colors}) => {
    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.up("sm"));

    useEffect(() => {
        dispatch(resetAllBookings());
        fetchMoreData();
    }, [dispatch])

    const fetchMoreData = () => {
        dispatch(listAllBookings({ hotelId: hotelId }));
    }

    const { bookingsList, showViewMore } = useSelector(
        ({
            admin: {
                bookings: { list, showViewMore },
            },
        }) => ({
            bookingsList: list,
            showViewMore,
        })
    );

    const columns = [
        {
            field: "booking_id",
            headerName: "Booking id",
            width: 200,
            renderCell: ({row: { booking_id }}) => (<small>{booking_id}</small>),
        },
        {
            field: "guest_name",
            headerName: "Guest info",
            width: 220,
            renderCell: ({ row: { guest_name, guest_email } }) => {
                return (
                    <Stack>
                        <Typography color={colors.greenAccent[400]}>
                            <b>{guest_name}</b>
                        </Typography>
                        <Typography fontSize={"small"}>
                            {guest_email || ""}
                        </Typography>
                    </Stack>
                )
            },
        },
        {
            field: "checkin_date",
            headerName: "Check-in date",
            width: 130,
            valueGetter: (params) => `${params.row.checkin_date ? moment.unix(params.row.checkin_date).format("YYYY-MM-DD") : "-"}`,
        },
        {
            field: "checkout_date",
            headerName: "Check-out date",
            width: 130,
            valueGetter: (params) => `${params.row.checkout_date ? moment.unix(params.row.checkout_date).format("YYYY-MM-DD") : "-"}`,
        },
        { 
            field: "total_price", 
            headerName: "Price", 
            width: 100,
            renderCell: ({ row: { custom_price, total_price } }) => `${custom_price ? custom_price : total_price}`,
        },
        { field: "total_rooms", headerName: "Total rooms", width: 80 },
        { 
            field: "booking_from", 
            headerName: "Booking from", 
            width: 150,
            renderCell: ({ row: { booking_from } }) => `${booking_from?.replace("_", " ")}`, 
        },
        {
            field: "status",
            headerName: "Status",
            width: 120,
            renderCell: ({ row: { status } }) => <StatusChip status={status} />,
        },
    ];

    return (
        <Box>
            <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                m="20px 0"
            >
                <Typography fontWeight="bold" variant={smScreen ? "h3" : "h4"}>
                    BOOKINGS
                </Typography>
            </Box>
            <Box
                m="8px 0 0 0"
                height="80vh"
                sx={tableStyle(colors)}
            >
                <DataGrid rows={bookingsList} columns={columns} getRowId={(row) => row?._id} />
            </Box>
        </Box>
    );
};

export default Bookings;
