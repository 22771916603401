import {
    SAVE_ALL_PACKAGES,
    RESET_ALL_PACKAGES,
    SAVE_ALL_HOTEL_PACKAGES,
    RESET_ALL_HOTEL_PACKAGES
} from "../types";

const INITIAL_STATE = {
    packages: {
        list: [],
        showViewMore: false,
        pageNumber: 1,
        pageSize: 50,
    },
    hotelPackages: {
        list: [],
        showViewMore: false,
        pageNumber: 1,
        pageSize: 50,
    }
};

const packageReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {

        case SAVE_ALL_PACKAGES:
            return {
                ...state,
                packages: {
                    pageSize: 10,
                    ...payload,
                },
            };

        case RESET_ALL_PACKAGES:
            return {
                ...state,
                packages: INITIAL_STATE.packages,
            };

        case SAVE_ALL_HOTEL_PACKAGES:
            return {
                ...state,
                hotelPackages: {
                    pageSize: 10,
                    ...payload,
                },
            };

        case RESET_ALL_HOTEL_PACKAGES:
            return {
                ...state,
                hotelPackages: INITIAL_STATE.hotelPackages,
            };

        default:
            return state;
    }
};

export default packageReducer;
