/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { loginWithOtp, sendOtp, toggleLoading } from "../../store/actions";
import { Box, Card, Container, Grid } from "@mui/material";
import { toast } from "react-toastify";
import LoginWithOtp from "./LoginWithOtp";
import SendOtp from "./SendOtp";
import { useNavigate } from "react-router-dom";

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");

  useEffect(() => {
    localStorage.clear();
    dispatch(toggleLoading(false));
  }, []);

  const handleSendOtp = async (value) => {
    let result = await dispatch(sendOtp(value));
    if (result) {
        toast.success("OTP sent successfully");
      setEmail(value.email);
    }
  };

  const handleOtpLogin = async (value) => {
    let status = await dispatch(loginWithOtp({ email: email, otp: value.otp }));
    if (status === true) {
      navigate("/dashboard");
    }
  };

  return (
    <Container>
      <Box width="100%" height="100vh" sx={{ display: "flex", alignItems: "center" }}>
        <Grid container justifyContent="center">
          <Grid item lg={5} md={6} sm={8} xs={12}>
            <Card sx={{ m: 2, bgcolor: "transparent !important" }}>
              {email === "" ? (
                <SendOtp onSubmit={handleSendOtp} />
              ) : (
                <LoginWithOtp onSubmit={handleOtpLogin} />
              )}
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default Login;
