/* eslint-disable */
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";
import { MyProSidebarProvider } from "../views/global/sidebar/sidebarContext";
import Loader from "../components/Loader";
import Topbar from "../views/global/Topbar";

import { adminRoutes } from "../routes";
import { isLoggedIn } from "../utils";

function AdminLayout() {
  const navigate = useNavigate();
  const location = useLocation()

  const { isAuthenticated, loading } = useSelector(({ auth: { token }, loading }) => ({
    isAuthenticated: isLoggedIn(token),
    loading,
  }));

  useEffect(() => {
    if (location.pathname === '/') {
      if (isAuthenticated){
        navigate('/dashboard');
      }
      else {
        navigate('/login');
      }
    }
  }, [navigate, location.pathname, isAuthenticated])

  return (
    <>
      <Loader visible={loading} />
      <MyProSidebarProvider>
        <div style={{ height: "100%", width: "100%" }}>
          <main>
            <Topbar />
            {adminRoutes.map((prop, key) => {
              if (prop.path && isAuthenticated) {
                return <Routes key={key}> <Route path={prop.path} element={prop.component}/> </Routes>;
              } else {
                return <Navigate to={"/login"} key={key} />;
              }
            })}
          </main>
        </div>
      </MyProSidebarProvider>
    </>
  );
}

export default AdminLayout;
