export const ADD_NEW_PACKAGE = `mutation ($name: String!, $description: String, $fixedRate: Float, $ratePerRoom: Float, $ratePerNight: Float, $bookingPercentage: Float) {
  add_new_package(name: $name, description: $description, fixed_rate: $fixedRate, rate_per_room: $ratePerRoom, rate_per_night: $ratePerNight, booking_percentage: $bookingPercentage){
    message
  }
}`;

export const LIST_ALL_PACKAGES = `query ($pageSize: Int, $pageNumber: Int) {
  list_all_packages(page_size: $pageSize, page_number: $pageNumber){
    message
    data {
      _id
      name
      description
      fixed_rate
      rate_per_room
      rate_per_night
      booking_percentage
      is_active
    }
    hasMore
  }
}
`;

export const LIST_ALL_HOTEL_PACKAGES = `query ($hotelId: String, $pageSize: Int, $pageNumber: Int) {
  list_all_hotel_packages(hotel_id: $hotelId, page_size: $pageSize, page_number: $pageNumber){
    message
    data {
      _id
      plan
      start_date
      end_date
      discount_perc
      is_active
      pmt_status
      package_id {
        _id
        name
      }
    }
    hasMore
  }
}
`;

export const ADD_NEW_HOTEL_PACKAGE = `mutation ($hotelId: String!, $packageId: String! $plan: Int, $startDate: Int!, $endDate: Int!, $discountPerc: Int) {
  add_new_hotel_package(hotel_id: $hotelId, package_id: $packageId, plan: $plan, start_date: $startDate, end_date: $endDate, discount_perc: $discountPerc){
    message
  }
}`;

export const ACTIVATE_HOTEL_PACKAGE = `mutation ($id: String!) {
  activate_hotel_package(_id: $id){
    message
  }
}`;

export const UPDATE_HOTEL_PACKAGE = `mutation ($id: String!, $pmtStatus: String!) {
  update_hotel_package(_id: $id, pmt_status: $pmtStatus){
    message
  }
}`;