/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { Box, Button, CircularProgress, FormControlLabel, Modal, Radio, RadioGroup, Stack, TextField, useMediaQuery, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
// import { mockDataInvoices } from "../../data/mockData";

import { addNewPackage, listAllPackages, resetAllPackages } from "../../store/actions";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import { Add } from "@mui/icons-material";
import Header from "../../components/Header";
import * as yup from "yup";
import { tableStyle } from "../mui-styles/table";

const Packages = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
    const colors = tokens(theme.palette.mode);

    const [pref, setPref] = useState("fixedRate");
    const [prefValue, setPrefValue] = useState(0);
    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setPref("fixedRate")
        setPrefValue(0)
        setOpen(true)
    }
    const handleClose = () => setOpen(false);

    const { isLoading, packagesList, showViewMore } = useSelector(
        ({
            loading,
            package: {
                packages: { list, showViewMore },
            },
        }) => ({
            isLoading: loading,
            packagesList: list,
            showViewMore,
        })
    );

    useEffect(() => {
        dispatch(resetAllPackages());
        fetchMoreData();
    }, [dispatch])

    const fetchMoreData = () => {
        dispatch(listAllPackages());
    }

    const columns = [
        // { field: "_id", headerName: "No", width: 200 },
        {
            field: "name",
            headerName: "Name",
            width: 200,
            cellClassName: "name-column--cell",
        },
        { 
            field: "description", 
            headerName: "Description", 
            width: 200,
            renderCell: ({ row: { description } }) => `${description || "-"}`, 
        },
        { field: "fixed_rate", headerName: "Fixed Rate", width: 120 },
        { field: "rate_per_room", headerName: "Rate per room", width: 120 },
        { field: "rate_per_night", headerName: "Rate per room night", width: 150 },
        // { field: "booking_percentage", headerName: "Percentage", width: 120 },
        // { field: "is_active", headerName: "Availability", width: 100 },
    ];

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: smScreen ? 400 : "90%",
        bgcolor: "#1F2A40",
        outline: "none",
        boxShadow: 24,
        p: 2,
    };

    const handleFormSubmit = async (values) => {
        let data = {
            ...values,
            [pref]: prefValue,
        }
        console.log(data);
        await dispatch(addNewPackage(data));
        handleClose();
    };
    const initialValues = {
        name: "",
        description: "",
    };
    const checkoutSchema = yup.object().shape({
        name: yup.string().required("Required"),
    });

    return (
        <Box m="20px" mb={15}>
            <Box
                display={smScreen ? "flex" : "block"}
                flexDirection={smScreen ? "row" : "column"}
                justifyContent={smScreen ? "space-between" : "start"}
                alignItems={smScreen ? "center" : "start"}
                m="20px 0"
            >
                <Header title="PACKAGES" subtitle="welcome to Packages" />

                <Box>
                    <Button
                        sx={{
                            backgroundColor: colors.blueAccent[700],
                            color: colors.grey[100],
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px",
                        }}
                        onClick={handleOpen}
                        disabled={localStorage.getItem("accountType") !== "admin"}
                    >
                        <Add sx={{ mr: "10px" }} />
                        Add new package
                    </Button>
                </Box>
            </Box>
            <Box
                m="8px 0 0 0"
                height="80vh"
                sx={tableStyle(colors)}
            >
                <DataGrid rows={packagesList} columns={columns} getRowId={(row) => row?._id} />
            </Box>

            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={modalStyle}>
                    <Header title="ADD NEW PACKAGE" titleVariant="h4" />

                    <Formik
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={checkoutSchema}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                            <form onSubmit={handleSubmit}>

                                <Stack spacing={2}>
                                    <TextField
                                        fullWidth
                                        required
                                        variant="filled"
                                        type="text"
                                        label="Name"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.name}
                                        name="name"
                                        error={!!touched.name && !!errors.name}
                                        helperText={touched.name && errors.name}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Description"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.description}
                                        name="description"
                                        error={!!touched.description && !!errors.description}
                                        helperText={touched.description && errors.description}
                                    />
                                    <RadioGroup
                                        row
                                        value={pref}
                                        onChange={(e) => setPref(e.target.value)}
                                    >
                                        <FormControlLabel value="fixedRate" control={<Radio />} label="Fixed rate" />
                                        <FormControlLabel value="ratePerRoom" control={<Radio />} label="Rate per room" />
                                        <FormControlLabel value="ratePerNight" control={<Radio />} label="Rate per room night" />
                                        {/* <FormControlLabel value="bookingPercentage" control={<Radio />} label="Percentage" /> */}
                                    </RadioGroup>
                                    <TextField
                                        fullWidth
                                        required
                                        variant="filled"
                                        type="number"
                                        label={
                                            pref === "fixedRate" ? "Fixed rate" : 
                                            pref === "ratePerRoom" ? "Rate per room" : 
                                            pref === "ratePerNight" ? "Rate per room night" : ""
                                        }
                                        onChange={(e) => setPrefValue(e.target.value)}
                                        value={prefValue}
                                        name={pref}
                                        inputProps={{ min: 1 }}
                                    />
                                    <Button
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        color="secondary"
                                        variant="contained"
                                        sx={{ fontSize: "15px" }}
                                    >
                                        {isLoading ? <CircularProgress color="inherit" size={24} /> : <b>Submit</b>}
                                    </Button>
                                </Stack>
                            </form>
                        )}
                    </Formik>
                </Box>
            </Modal>
        </Box>
    );
};

export default Packages;
